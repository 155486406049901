import { PrinticularProductTemplateTagEntity } from "@jackfruit/common"
import { call, put } from "redux-saga/effects"
import { PrinticularApi } from "~/services/PrinticularApi"
import { productTemplateTags } from "../state/productTemplates"
import { getApi } from "./api"

export function* downloadproductTemplateTags(tags: string[]) {
  yield put(productTemplateTags.actions.setIsLoading(true))
  yield put(productTemplateTags.actions.setHasError(false))
  yield put(productTemplateTags.actions.setError(""))

  try {
    const printicularApi: PrinticularApi = yield call(getApi)
    const allProductTemplateTags: PrinticularProductTemplateTagEntity[] =
      yield call([printicularApi, "getProductTemplateTags"], tags)
    yield put(productTemplateTags.actions.upsertMany(allProductTemplateTags))
  } catch (error: any) {
    yield put(productTemplateTags.actions.setError(error.message))
    yield put(productTemplateTags.actions.setHasError(true))
  } finally {
    yield put(productTemplateTags.actions.setIsLoading(false))
  }
}
