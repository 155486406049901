import {
  Box,
  Icon,
  Link as ChakraLink,
  Stack,
  useTheme,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiLogOut } from "react-icons/fi"
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri"
import { useBoolean } from "react-use"
import { useAuth } from "~/hooks/useAuth"
import { useSettings } from "~/hooks/useSettings"
import { LoggedInMessage } from "../MyAccount/MyAccountMenu"
import { kebabCase } from "lodash"

const MyAccountAccordion = () => {
  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  const [isOpen, setIsOpen] = useBoolean(false)

  if (!isUserAccountEnabled) {
    return null
  }

  return (
    <>
      <ChakraLink
        id="p-header-my-account-mobile"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={setIsOpen}
        width="100%"
        fontWeight="bold"
        px={2}
      >
        <LoggedInMessage />
        <Icon
          as={isOpen ? RiArrowUpSFill : RiArrowDownSFill}
          ml={1}
          boxSize={5}
        />
      </ChakraLink>
      {isOpen && <MyAccountAccordionList />}
    </>
  )
}

export default MyAccountAccordion

const MyAccountAccordionList = () => {
  const { t } = useTranslation()
  const { logout } = useAuth()

  return (
    <Stack spacing={3} px={5} width="100%">
      <MyAccountAccordionItem
        title={t("components.header.MyAccount.MyAccountAccordion.MyAccount")}
        link="/account"
      />
      <MyAccountAccordionItem
        title={t(
          "components.header.MyAccount.MyAccountAccordion.OrdersHistory"
        )}
        link="/account/orders"
      />
      <MyAccountAccordionItem
        title={t(
          "components.header.MyAccount.MyAccountAccordion.ChangePassword"
        )}
        link="/account/change-password"
      />

      <ChakraLink
        id="my-account-sign-out-mobile"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="start"
        color="gray.500"
        onClick={logout}
      >
        <span>
          {t("components.header.MyAccount.MyAccountAccordion.SignOut")}
        </span>
        <Icon as={FiLogOut} ml={3} boxSize={4} />
      </ChakraLink>
    </Stack>
  )
}

const MyAccountAccordionItem = ({
  title,
  link,
}: {
  title: string
  link: string
}) => {
  const theme = useTheme()
  const titleToKebabCase = kebabCase(title)

  return (
    <Box _hover={{ textDecoration: "underline" }}>
      <Link
        id={`${titleToKebabCase}-link-mobile`}
        to={link}
        style={{
          display: "block",
          width: "100%",
        }}
        activeStyle={{
          color: theme.colors.primary["500"],
        }}
      >
        {title}
      </Link>
    </Box>
  )
}
