import { FulfillmentTypes, PrintServiceEntityHydrated } from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"
import { RootState } from "../store"

const { slice: printServices, selectors } = createEntitySlice<
  PrintServiceEntityHydrated
>("printServices")

const selectByFulfillment = (
  state: RootState,
  fulfillmentType: FulfillmentTypes
) => selectors.selectAll(state).find(p => p.fulfillmentType === fulfillmentType)

const printServicesSelector = {
  ...selectors,
  selectByFulfillment,
}

export { printServices, printServicesSelector }
