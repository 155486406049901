import {
  BlockEntity,
  BlockEntityHydrated,
  EntityId,
  PageEntity,
} from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { call, select } from "redux-saga/effects"
import { blocksSelector } from "../state/blocks"
import { RootState } from "../store"
import { getPage } from "./page"

export function* getBlock(payload: {
  blockId: EntityId
}): SagaIterator<BlockEntityHydrated> {
  const { blockId } = payload
  const block: BlockEntityHydrated = yield select((state: RootState) =>
    blocksSelector.selectById(state, blockId)
  )

  return block
}

export function* getBlocks(payload: {
  ids: EntityId[]
}): SagaIterator<BlockEntity[]> {
  const { ids } = payload

  const blocks: BlockEntity[] = yield select((state: RootState) =>
    blocksSelector.selectByIds(state, ids)
  )

  return blocks
}

export function* getTemplateBlocksForPage(payload: { pageId: EntityId }) {
  const { pageId } = payload

  const page: PageEntity = yield call(getPage, { pageId })
  const blocks: BlockEntity[] = yield call(getBlocks, { ids: page.blocks })

  return blocks.filter(block => block.type === "template")
}
