import CryptoJS from "crypto-js"
import Compressor from "compressorjs"
import { clamp } from "lodash"

export interface ImageDimensions {
  width: number
  height: number
}

export class Files {
  public static async generateKey(file: File) {
    const fileContent = await Files.readAsArrayBuffer(file)
    const md5 = CryptoJS.MD5(CryptoJS.lib.WordArray.create(fileContent))

    return md5
  }

  public static async readAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        resolve(reader.result as string)
      })
      reader.addEventListener("error", () => {
        reject(`Unable to read file ${file.name}`)
      })
      reader.readAsText(file)
    })
  }

  public static async readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        resolve(reader.result as ArrayBuffer)
      })
      reader.addEventListener("error", () => {
        reject(`Unable to read file ${file.name}`)
      })
      reader.readAsArrayBuffer(file)
    })
  }

  public static async getImageDimensions(file: File): Promise<ImageDimensions> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const image = new Image()
        image.onload = () => {
          resolve({
            height: image.height,
            width: image.width,
          })
        }
        image.onerror = error => {
          reject(error)
        }

        image.src = fileReader.result as string
      }

      fileReader.readAsDataURL(file)
    })
  }

  public static async resizeImage(file: File): Promise<File> {
    const maxWidthFromScreen = clamp(window.screen.width * 0.8, 1000, 1920)
    return new Promise((resolve, reject) => {
      // @see https://github.com/fengyuanchen/compressorjs
      // for more options
      new Compressor(file, {
        quality: 0.8,
        maxWidth: maxWidthFromScreen,
        maxHeight: 1000,
        checkOrientation: false,
        success(result: any) {
          resolve(result as File)
        },
        error(err) {
          reject(err)
        },
      })
    })
  }

  public static async compressImage(file: File, rate: number): Promise<File> {
    return new Promise((resolve, reject) => {
      // @see https://github.com/fengyuanchen/compressorjs
      // for more options
      new Compressor(file, {
        quality: rate,
        checkOrientation: true,
        success(result: any) {
          resolve(result as File)
        },
        error(err) {
          reject(err)
        },
      })
    })
  }
}
