import { BlockType } from "@jackfruit/common"
import { orderBy } from "lodash"
import { useBlocks } from "./useBlocks"

export const actionableToBlocks: BlockType[] = [
  "image-upload",
  "fulfillment",
  "template",
  "cart",
  "checkout",
  "store-search",
  "photos-uploaded",
  "product-grid",
]

const minimumActionableBlocks: BlockType[] = [
  "image-upload",
  "template",
  "product-grid",
]

export const useNextActionableBlock = (currentBlock: BlockType) => {
  const { blocks } = useBlocks()

  const sortedBlocks = orderBy(blocks, ["order"], ["asc"])
  const currentBlockIndex = sortedBlocks.findIndex(
    block => block.type === currentBlock
  )
  const nextActionableBlock = sortedBlocks.find(
    (b, i) =>
      i > currentBlockIndex &&
      currentBlock !== b.type &&
      actionableToBlocks.includes(b.type)
  )

  const hasMinimumActionableBlockRequirement = !!sortedBlocks.find(b =>
    minimumActionableBlocks.includes(b.type)
  )

  const nextBlockType = nextActionableBlock?.type!

  return { nextBlockType, hasMinimumActionableBlockRequirement }
}
