import {
  EntityId,
  PrinticularCategoryEntity,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateEntity,
} from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { call, select } from "redux-saga/effects"
import { categorySelectors } from "../state/categories"
import {
  productTemplateCategorySelectors,
  productTemplateSelectors,
} from "../state/productTemplates"
import { RootState } from "../store"

export function* getProductTemplate(
  productTemplateId: EntityId
): SagaIterator<PrinticularProductTemplateEntity> {
  const productTemplate: PrinticularProductTemplateEntity = yield select(
    (state: RootState) =>
      productTemplateSelectors.selectById(state, productTemplateId)
  )

  return productTemplate
}

export function* getProductTemplateCategories(
  productTemplateId: EntityId
): SagaIterator<PrinticularCategoryEntity[]> {
  const productTemplate: PrinticularProductTemplateEntity = yield call(
    getProductTemplate,
    productTemplateId
  )

  const productTemplateCategories: PrinticularProductTemplateCategoryEntity[] = yield select(
    state =>
      productTemplateCategorySelectors.selectByIds(
        state,
        productTemplate.productTemplateCategories
      )
  )
  const categoriesIds = productTemplateCategories.map(
    productTemplateCategorie => productTemplateCategorie.category
  )

  const categories: PrinticularCategoryEntity[] = yield select(state =>
    categorySelectors.selectByIds(state, categoriesIds)
  )

  return categories
}
