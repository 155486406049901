import { Flex, Heading } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"
import MegaMenuCellHtml from "./MegaMenuCellHtml"
import MegaMenuCellLinks from "./MegaMenuCellLinks"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuCell: React.FC<Props> = ({ cell }) => {
  const title = cell.title ? (
    <Heading as="h2" fontWeight="medium" fontSize="md" mb={4} color="gray.800">
      {cell.title}
    </Heading>
  ) : undefined

  return (
    <Flex direction="column" p={0}>
      {title}
      {cell.type === "html" ? (
        <MegaMenuCellHtml cell={cell} />
      ) : (
        <MegaMenuCellLinks cell={cell} />
      )}
    </Flex>
  )
}

export default MegaMenuCell
