import { Flex } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"
import FooterBlockMegamenuHtml from "./FooterBlockMegamenuHtml"
import FooterBlockMegamenuNavigation from "./FooterBlockMegamenuNavigation"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const FooterBlockMegamenuCell: React.FC<Props> = ({ cell }) => {
  const { type } = cell
  const isNavigation = type === "navigation"
  const isHtml = type === "html"

  return (
    <Flex>
      {isNavigation && <FooterBlockMegamenuNavigation cell={cell} />}
      {isHtml && <FooterBlockMegamenuHtml cell={cell} />}
    </Flex>
  )
}

export default FooterBlockMegamenuCell
