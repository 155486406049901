import { EntityId, PageEntity } from "@jackfruit/common"
import { call, getContext, select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { pagesSelector } from "../state/pages"
import { RootState } from "../store"

export function* getPage(payload: {
  pageId: EntityId
}): SagaIterator<PageEntity> {
  const { pageId } = payload
  const page: PageEntity = yield select((state: RootState) =>
    pagesSelector.selectById(state, pageId)
  )

  return page
}

export function* getAllPAges(): SagaIterator<PageEntity[]> {
  const pages: PageEntity[] = yield select(pagesSelector.selectAll)

  return pages
}

export function* getCurrentPage(): SagaIterator<PageEntity> {
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  const page: PageEntity = yield call(getPage, { pageId })

  return page
}
