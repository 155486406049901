import React from "react"
import BootstrapPage from "./components/BootstrapPage"
import "./PageWrapper.scss"
import Layout from "./templates/layout/default/Layout"

const PageWrapper = ({
  element,
  props,
}: {
  element: JSX.Element
  props: any
}) => {
  const { config, page } =
    props.pageContext || props.pageResources.json.pageContext

  // maintenance, disable layout
  const isMaintenance = new RegExp("(.*)/maintenance").test(props.location.href)
  if (isMaintenance) {
    return element
  }

  // no config available
  // this is probably a 404, we need to bypass the bootstraping of the application
  // in case of an unexisting page
  if (!config) {
    return <Layout>{element}</Layout>
  }

  return <BootstrapPage pageId={page.id}>{element}</BootstrapPage>
}

export default PageWrapper
