import { EntityId } from "@jackfruit/common"
import React, { useMemo } from "react"
import { useBootstrapPage } from "~/hooks/useBootstrapPage"
import { CurrentPageIdContext } from "~/hooks/useCurrentPageId"

interface Props {
  pageId: EntityId
  children: React.ReactNode
}

const BootstrapPage: React.FC<Props> = ({ children, pageId }) => {
  useBootstrapPage(pageId)
  const currentPageIdContext = useMemo(() => {
    return { id: pageId }
  }, [pageId])

  return (
    <CurrentPageIdContext.Provider value={currentPageIdContext}>
      {children}
    </CurrentPageIdContext.Provider>
  )
}

export default React.memo(BootstrapPage)
