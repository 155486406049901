import React from "react"
import { useBootstrapApplication } from "~/hooks/useBootstrapApplication"

interface Props {
  children: React.ReactElement
}

const BootstrapApplication: React.FC<Props> = ({ children }) => {
  useBootstrapApplication()

  return children
}

export default BootstrapApplication
