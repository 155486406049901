import { useEffect, useRef, useState } from "react"
import { useWindowSize } from "react-use"

const defaultBox = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

interface PanelPosition {
  left: number | undefined
  right: number | undefined
}

export const useIdealMenuPosition = () => {
  const [position, setPosition] = useState<PanelPosition>({
    left: undefined,
    right: undefined,
  })
  const menuItemRef = useRef<HTMLDivElement>(null)
  const menuPanelRef = useRef<HTMLDivElement>(null)

  const { width, height } = useWindowSize()

  useEffect(() => {
    const menuItemBox =
      menuItemRef.current?.getBoundingClientRect() ?? defaultBox
    const panelBox = menuPanelRef.current?.getBoundingClientRect() ?? defaultBox

    const menuItemCenter = menuItemBox?.x + menuItemBox?.width / 2
    const panelHalfWidth = panelBox?.width / 2
    const idealPanelX = menuItemCenter - panelHalfWidth
    const calculatedPanelX = idealPanelX < 0 ? 0 : idealPanelX
    // if panel overflow to the right, set right to 0
    // cannot just use clamp as some browsed will not account
    // for the scrollbar width, 25 being a magic number to account for it

    if (calculatedPanelX > width - panelBox?.width - 25) {
      setPosition({ left: undefined, right: 0 })
    } else {
      setPosition({ left: calculatedPanelX, right: undefined })
    }
  }, [width, height])

  return { menuItemRef, menuPanelRef, position }
}
