import { Box, Flex, Icon, Link, Text } from "@chakra-ui/react"
import { AppEntityHydrated, BlockFooterEntityHydrated } from "@jackfruit/common"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaArrowRight } from "react-icons/fa"
import Wrapper from "~/components/Wrapper"
import { useSettings } from "~/hooks/useSettings"
import { ReactComponent as MeaLogo } from "~/images/MeaLogo.svg"
import FooterBlockCountrySelector from "./FooterBlockCountrySelector"
import FooterBlockMegamenu from "./FooterBlockMegamenu"

interface Props {
  appConfig: AppEntityHydrated
  config: BlockFooterEntityHydrated
}

const FooterBlockMegamenuContent: React.FC<Props> = ({ appConfig, config }) => {
  const { t } = useTranslation()

  const {
    common: {
      showPoweredByAutopilotBanner,
      poweredByAutopilotBannerText,
      poweredByAutopilotRedirectUrl,
    },
  } = appConfig

  const {
    settings: { isSitemapEnabled, isSitemapHideFooter, sitemapLinkLabel },
  } = useSettings()

  const row1Bg = config.row1Bg || "gray.100"
  const row2Bg = config.row2Bg || "gray.100"
  const row3Bg = config.row3Bg || "gray.200"

  const row1TextColor = config.row1TextColor || "gray.700"
  const row2TextColor = config.row2TextColor || "gray.700"
  const row3TextColor = config.row3TextColor || "gray.700"

  const isSitemapLinkVisible = Boolean(isSitemapEnabled && !isSitemapHideFooter)
  const shouldShowCountrySelector = config.showCountrySelector
  const countrySelectorConfiguration = config.countrySelectorConfiguration || []

  const shouldShowFirstRow = Boolean(
    isSitemapLinkVisible || shouldShowCountrySelector
  )

  return (
    <Box>
      {shouldShowFirstRow && (
        <Wrapper
          id="p-footer-sitemap-wrapper"
          bg={row1Bg}
          className="block-footer-row block-footer-row--1"
          py={8}
          sx={{
            color: row1TextColor,
            h4: {
              color: row1TextColor,
            },
          }}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{
              base: "column",
              sm: "row",
            }}
            gap={4}
          >
            {isSitemapLinkVisible && (
              <Flex
                flex={1}
                fontSize="lg"
                fontWeight="bold"
                className="sitemap-link"
              >
                <Box _hover={{ textDecoration: "underline" }}>
                  <GatsbyLink id="p-footer-sitemap-link" to="/sitemap">
                    {Boolean(sitemapLinkLabel)
                      ? sitemapLinkLabel
                      : t("components.blocks.FooterBlockA.DefaultSitemapLabel")}
                    <Icon as={FaArrowRight} boxSize={3} ml={2} />
                  </GatsbyLink>
                </Box>
              </Flex>
            )}
            {Boolean(shouldShowCountrySelector) && (
              <Flex flex={1} justifyContent="flex-end">
                <FooterBlockCountrySelector
                  options={countrySelectorConfiguration}
                />
              </Flex>
            )}
          </Flex>
        </Wrapper>
      )}
      <Wrapper
        id="p-footer-sitemap-wrapper"
        bg={row2Bg}
        className="block-footer-row block-footer-row--2"
        py={8}
        sx={{
          color: row2TextColor,
          h4: {
            color: row2TextColor,
          },
        }}
      >
        <FooterBlockMegamenu config={config} appConfig={appConfig} />
      </Wrapper>
      {Boolean(showPoweredByAutopilotBanner) && (
        <Wrapper
          bg={row3Bg}
          className="block-footer-row block-footer-row--3"
          sx={{
            color: row3TextColor,
            p: {
              color: row3TextColor,
            },
            h4: {
              color: row3TextColor,
            },
            fill: row3TextColor,
          }}
        >
          <Flex py={5} justify="center" align="center" direction="column">
            <Link href={poweredByAutopilotRedirectUrl} isExternal>
              <Flex gap={3}>
                <MeaLogo width="50px" />
                <Text fontSize="xs" my="auto">
                  {poweredByAutopilotBannerText ||
                    t("components.blocks.FooterBlockA.PoweredByAutopilot")}
                </Text>
              </Flex>
            </Link>
          </Flex>
        </Wrapper>
      )}
    </Box>
  )
}

export default FooterBlockMegamenuContent
