import { z } from "zod"

export const deliveryOrderBaseValidationSchema = z.object({
  fulfillment: z.string(),
  lineItems: z
    .array(
      z.object({
        isReady: z.literal<boolean>(true),
      })
    )
    .min(1),
  printService: z.object({}).required(),
  printServiceProducts: z.array(z.object({})).min(1),
  user: z.object({}),
})

export const deliveryOrderAddressValidationSchema = z.object({
  address: z.object({
    line1: z.string().min(2),
    line2: z.string().optional(),
    city: z.string().min(2),
    country: z.string().min(2),
    postcode: z.string().min(2),
  }),
})

export const deliveryOrderPaymentValidationSchema = z.object({
  payment: z.object({}).required(),
})

export const pickupOrderValidationSchema = z.object({
  fulfillment: z.string(),
  store: z.object({}).required(),
  payment: z.object({}).required(),
  address: z.object({}),
  lineItems: z
    .array(
      z.object({
        isReady: z.literal<boolean>(true),
      })
    )
    .min(1),
  printService: z.object({}).required(),
  printServiceProducts: z.array(z.object({})).min(1),
})
