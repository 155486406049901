import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"

const separatorPropConfig = {
  borderBottomWidth: 1,
  paddingBottom: 16,
  borderColor: "gray.200",
}

interface Props extends BoxProps {
  hasSeparator?: boolean
}

const Wrapper: React.FC<Props> = ({
  children,
  hasSeparator = false,
  ...props
}) => {
  const separatorProps = hasSeparator ? separatorPropConfig : {}
  const { maxWidth = "90em", ...rest } = props

  return (
    <Box
      {...rest}
      {...separatorProps}
      px={{
        base: "4",
        md: "10",
      }}
    >
      <Box maxWidth={maxWidth} marginX="auto">
        {children}
      </Box>
    </Box>
  )
}

export default Wrapper
