import { Entity, EntityId } from "@jackfruit/common"
import {
  Comparer,
  createEntityAdapter,
  createSelector,
  createSlice,
  IdSelector,
} from "@reduxjs/toolkit"
import { RootState } from "./store"

// see https://redux-toolkit.js.org/api/createEntityAdapter
export const createEntitySlice = <T extends Entity>(
  name: keyof RootState,
  options: {
    selectId?: IdSelector<T>
    sortComparer?: false | Comparer<T>
  } = {}
) => {
  const adapter = createEntityAdapter<T>(options)

  const slice = createSlice({
    name,
    initialState: adapter.getInitialState({
      isLoading: false,
      hasError: false,
      error: "",
    }),
    reducers: {
      setIsLoading(state, action) {
        state.isLoading = action.payload
      },
      setHasError(state, action) {
        state.hasError = action.payload
      },
      setError(state, action) {
        state.error = action.payload
      },
      // cannot use generics with createSlice and entityAdapter for now
      // ignoring typing to prevent ide from complaining
      // @ts-ignore
      addOne: adapter.addOne,
      // @ts-ignore
      addMany: adapter.addMany,
      // @ts-ignore
      setAll: adapter.setAll,

      // @ts-ignore
      removeOne: adapter.removeOne,
      // @ts-ignore
      removeMany: adapter.removeMany,
      // @ts-ignore
      removeAll: adapter.removeAll,

      // @ts-ignore
      updateOne: adapter.updateOne,
      // @ts-ignore
      updateMany: adapter.updateMany,

      // @ts-ignore
      upsertOne: adapter.upsertOne,
      // @ts-ignore
      upsertMany: adapter.upsertMany,
    },
  })

  const getEntities = (state: any) => state[name].entities
  const getIds = (_: any, ids: EntityId[]) => ids || []

  const makeSelectByIds = () => {
    const selectByIds = createSelector(getEntities, getIds, (entities, ids) => {
      return ids.map(id => entities[id]) as T[]
    })

    return selectByIds
  }

  const adapterSelectors = adapter.getSelectors<RootState>(
    state => state[name] as any
  ) // TODO fix this typing issue

  const selectors = {
    ...adapterSelectors,
    selectByIds: makeSelectByIds(),
  }

  return { slice, selectors }
}

/**
 * A helper function to create the parameter selectors
 * @param selector
 * @returns the parameter selector
 */
export const createParameterSelector = <T>(
  selector: (params: T) => any // @TODO Type should be of the returned param
): ((_: RootState, params: T) => ReturnType<typeof selector>) => {
  return (_, params) => selector(params)
}
