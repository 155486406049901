import { Flex, Icon, Link } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaUserCircle } from "react-icons/fa"
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri"
import { useBoolean } from "react-use"
import { useSettings } from "~/hooks/useSettings"
import SignInForm from "./SignInForm"

const SignInAccordion = () => {
  const { t } = useTranslation()
  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  const [isOpen, setIsOpen] = useBoolean(false)

  if (!isUserAccountEnabled) {
    return null
  }

  return (
    <>
      <Link
        id="p-header-sign-in-mobile"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={setIsOpen}
        width="100%"
        fontWeight="bold"
        px={2}
      >
        <Flex alignItems="center">
          <Icon as={FaUserCircle} mr={2} boxSize={5} />
          {t("components.header.SignIn.SignInAccordion.MyAccount")}
        </Flex>
        <Icon
          as={isOpen ? RiArrowUpSFill : RiArrowDownSFill}
          ml={1}
          boxSize={5}
        />
      </Link>
      {isOpen && <SignInForm />}
    </>
  )
}

export default SignInAccordion
