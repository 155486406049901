import { EntityId } from "@jackfruit/common"
import { call, put, select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { carts, cartsSelector } from "../state/carts"
import { lineItemsSelectors } from "../state/lineItems"
import { RootState } from "../store"
import { getCurrentPageSession } from "./pageSession"

export function* getCurrentCart(): SagaIterator<CartEntity> {
  const pageSession: PageSessionEntity = yield call(getCurrentPageSession)
  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )

  return cart
}

export function* getCartLineItems(): SagaIterator<LineItemEntity[]> {
  const cart: CartEntity = yield call(getCurrentCart)
  const lineItems: LineItemEntity[] = yield select((state: RootState) =>
    lineItemsSelectors.selectByIds(state, cart.lineItemIds)
  )

  return lineItems
}

export function* getCart(cartId: EntityId): SagaIterator<CartEntity> {
  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, cartId)
  )

  return cart
}

export function* addLineItemToCart(payload: {
  cartId: EntityId
  lineItemId: EntityId
}): SagaIterator {
  const { lineItemId, cartId } = payload
  const { updateOne: updateOneCart } = carts.actions

  let cart = null
  if (cartId) {
    cart = yield call(getCart, cartId)
  } else {
    cart = yield call(getCurrentCart)
  }

  yield put(
    updateOneCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, lineItemId],
      },
    })
  )
}
