import { SagaIterator } from "@redux-saga/types"
import { nanoid, PayloadAction } from "@reduxjs/toolkit"
import { put, select, takeEvery, getContext, call } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { GiftCertificateEntity } from "~/interfaces/entities/GiftCertificate"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { actions, DuplicateLineItemPayload } from "../process"
import { carts, cartsSelector } from "../state/carts"
import {
  giftCertificatesSelectors,
  giftCertificates,
} from "../state/giftCertificates"
import { imageRegions, imageRegionsSelectors } from "../state/imageRegions"
import { images, imagesSelectors } from "../state/images"
import {
  imageTransformations,
  imageTransformationsSelectors,
} from "../state/imageTransformations"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { productPages, productPagesSelectors } from "../state/productPages"
import { textRegions, textRegionsSelectors } from "../state/textRegions"
import { uploads, uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"

// ========================================================
// Duplicate one line item with all the
// Product pages -> image regions (one-to-many)
//    -> upload -> image -> image transform (one-to-one)
// ========================================================
export function* watchDuplicateLineItem() {
  yield takeEvery(actions.duplicateLineItem.type, processDuplicateLineItem)
}

export function* processDuplicateLineItem(
  action: PayloadAction<DuplicateLineItemPayload>
): SagaIterator<any> {
  const { addOne: addOneImageTransform } = imageTransformations.actions
  const { addOne: addOneImage } = images.actions
  const { addOne: addOneUpload } = uploads.actions
  const { addOne: addOneTextRegion } = textRegions.actions
  const { addOne: addOneImageRegion } = imageRegions.actions
  const { addOne: addOneProductPage } = productPages.actions
  const { addOne: addOneLineItem } = lineItems.actions

  const { lineItemId } = action.payload

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "duplicateItemInCart",
      data: { lineItemId },
    })
  )

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  const originalProductPages: ProductPageEntity[] = yield select(
    (state: RootState) =>
      productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )

  const newProductPageIds = []
  // For each product page of the line item
  for (const originalProductPage of originalProductPages) {
    const originalImageRegions: ImageRegionEntity[] = yield select(
      (state: RootState) =>
        imageRegionsSelectors.selectByIds(
          state,
          originalProductPage.imageRegionIds
        )
    )
    const originalTextRegions: TextRegionEntity[] = yield select(
      (state: RootState) =>
        textRegionsSelectors.selectByIds(
          state,
          originalProductPage.textRegionIds
        )
    )

    const newImageRegionIds = []
    // For each image region in the product page
    for (const originalImageRegion of originalImageRegions) {
      let newUploadId = null
      if (originalImageRegion.uploadId) {
        // Get upload -> image -> ImageTransform in order
        const originalUpload: UploadEntity = yield select((state: RootState) =>
          uploadsSelectors.selectById(state, originalImageRegion.uploadId!)
        )

        const originalImage: ImageEntity = yield select((state: RootState) =>
          imagesSelectors.selectById(state, originalUpload.imageId)
        )

        const originalImageTransform: ImageTransformationEntity = yield select(
          (state: RootState) =>
            imageTransformationsSelectors.selectById(
              state,
              originalImage.imageTransformId
            )
        )

        // Starting clone in reverse from ImageTransform -> Image -> Upload
        const newImageTransformId = nanoid()
        const newImageId = nanoid()
        newUploadId = nanoid()

        yield put(
          addOneImageTransform({
            ...originalImageTransform,
            id: newImageTransformId,
          })
        )

        yield put(
          addOneImage({
            ...originalImage,
            id: newImageId,
            imageTransformId: newImageTransformId,
          })
        )

        yield put(
          addOneUpload({
            ...originalUpload,
            id: newUploadId,
            imageId: newImageId,
          })
        )
      }

      const newImageRegionId = nanoid()

      yield put(
        addOneImageRegion({
          ...originalImageRegion,
          id: newImageRegionId,
          uploadId: newUploadId, // newUploadId could be null if upload does not exist
        })
      )
      newImageRegionIds.push(newImageRegionId)
    }

    const newTextRegionIds: string[] = []
    // For each text region in the product page
    for (const originalTextRegion of originalTextRegions) {
      const newTextRegionId = nanoid()

      yield put(
        addOneTextRegion({
          ...originalTextRegion,
          id: newTextRegionId,
        })
      )
      newTextRegionIds.push(newTextRegionId)
    }
    const newProductPageId = nanoid()

    yield put(
      addOneProductPage({
        ...originalProductPage,
        id: newProductPageId,
        imageRegionIds: newImageRegionIds,
        textRegionIds: newTextRegionIds,
      })
    )

    newProductPageIds.push(newProductPageId)
  }

  const giftCertificateId = lineItem.giftCertificateId ? nanoid() : undefined

  if (lineItem.giftCertificateId) {
    const giftCertificate: GiftCertificateEntity = yield select(
      (state: RootState) =>
        giftCertificatesSelectors.selectById(state, lineItem.giftCertificateId)
    )
    yield put(
      giftCertificates.actions.addOne({
        ...giftCertificate,
        id: giftCertificateId,
      })
    )
  }

  const newLineItemId = nanoid()
  const newLineItem = {
    ...lineItem,
    id: newLineItemId,
    productPageIds: newProductPageIds,
    giftCertificateId: giftCertificateId,
  }
  // create line item
  yield put(addOneLineItem(newLineItem))

  // finally add the duplicated line item to the current page's cart
  const { updateOne: updateCart } = carts.actions
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, currentPageId)
  )

  yield put(
    updateCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, newLineItemId],
      },
    })
  )

  yield put(
    actions.updateOrderSummary({
      reason: "duplicated a line item",
      reasonType: "cartChange",
    })
  )
}
