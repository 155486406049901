import { useSelector } from "react-redux"
import { RootState } from "~/redux/store"
import { SettingsEntity } from "@jackfruit/common"

export const useSettings = () => {
  const settings = useSelector<RootState, SettingsEntity>(
    state => state.settings.data
  )

  return { settings }
}
