import { EntityId } from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { select } from "redux-saga/effects"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"

export function* getUpload(uploadId: EntityId): SagaIterator<UploadEntity> {
  const upload: UploadEntity = yield select((state: RootState) =>
    uploadsSelectors.selectById(state, uploadId)
  )

  return upload
}
