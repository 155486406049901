import { Box } from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockHeaderEntity,
  PageEntityHydrated,
} from "@jackfruit/common"
import React from "react"
import { useSiteWideBanner } from "~/hooks/useSiteWideBanner"
import HeaderDesktop from "../header/HeaderDesktop"
import HeaderMobile from "../header/HeaderMobile"
import HtmlContent from "../HtmlContent"

interface Props {
  config: BlockHeaderEntity
  pages: PageEntityHydrated[]
  appConfig: AppEntityHydrated
}

const HeaderBlock: React.FC<Props> = ({ config, pages, appConfig }) => {
  const { displaySiteWideBanner, siteWideBannerContent } = useSiteWideBanner(
    config,
    appConfig
  )

  return (
    <Box>
      <Box display={{ base: "block", lg: "none" }}>
        <HeaderMobile config={config} pages={pages} appConfig={appConfig} />
      </Box>
      <Box display={{ base: "none", lg: "block" }}>
        <HeaderDesktop config={config} pages={pages} appConfig={appConfig} />
      </Box>
      {Boolean(displaySiteWideBanner) && (
        <HtmlContent html={siteWideBannerContent} config={appConfig} />
      )}
    </Box>
  )
}

export default HeaderBlock
