import {
  EntityId,
  PrinticularProductTemplateEntity,
  PrinticularProductTemplateVariant,
  PrintServiceProductEntity,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { call } from "redux-saga/effects"
import { floatsAreEqual, getProductRatio } from "~/services/Utils"
import { getPrintServiceProduct } from "./printServiceProducts"
import { getProductTemplate } from "./productTemplates"

export function* getProductTemplateVariantForProduct(payload: {
  productId: EntityId
  productTemplateId: EntityId
}): SagaIterator<
  {
    id: EntityId
    variant: PrinticularProductTemplateVariant
  }[]
> {
  const { productTemplateId, productId } = payload
  const productTemplate: PrinticularProductTemplateEntity = yield call(
    getProductTemplate,
    productTemplateId
  )
  const product: PrintServiceProductEntity = yield call(
    getPrintServiceProduct,
    productId
  )

  const allVariants = productTemplate.variants
  const { productRatio1, productRatio2 } = getProductRatio(product)

  return allVariants
    .map((variant, id) => ({ variant, id }))
    .filter(data => {
      const { variant } = data
      const [firstPage] = variant.pages
      const pageRatio = firstPage.width / firstPage.height

      return (
        floatsAreEqual(productRatio1, pageRatio) ||
        floatsAreEqual(productRatio2, pageRatio)
      )
    })
}
