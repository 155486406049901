import { nanoid } from "@reduxjs/toolkit"
import { OrderEntity } from "~/interfaces/entities/Order"
import { createEntitySlice } from "../createEntitySlice"

const { slice: orders, selectors: ordersSelector } =
  createEntitySlice<OrderEntity>("orders")

// todo: this doesn't seem to be used anywhere, maybe remove?
const ordersFactory = (
  data: Omit<
    OrderEntity,
    "id" | "error" | "hasError" | "isProcessing" | "isPendingAddress"
  >
): OrderEntity => {
  return {
    id: nanoid(),
    error: "",
    hasError: false,
    isProcessing: false,
    isPendingAddress: false,
    isUpdating: false,
    ...data,
  }
}

export { orders, ordersSelector, ordersFactory }
