// adapted from
// https://github.com/airbnb/visx/tree/master/packages/visx-text
import React from "react"
import { calculateText } from "./utils/textUtils"
import type { TextProps as TxtProps } from "./types"

export type TextProps = TxtProps

const SvgText: React.FC<TxtProps> = props => {
  const {
    textAnchor = "start",
    innerRef,
    innerTextRef,
    verticalAnchor,
    angle,
    lineHeight = "1em",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scaleToFit = true,
    capHeight,
    width,
    ...textProps
  } = props

  const { x = 0 } = textProps

  const value = String(props.children)
  const rows = value.split("\n")

  const { transform } = calculateText({
    ...props,
    children: rows[0],
  })

  return (
    <text
      ref={innerTextRef}
      transform={transform}
      {...textProps}
      textAnchor={textAnchor}
    >
      {rows.map((row, lineIndex) => {
        const children = row
        const { wordsByLines, startDy } = calculateText({
          ...props,
          children,
        })

        return (
          <React.Fragment key={lineIndex}>
            {wordsByLines.map((line, index) => {
              const dy = lineIndex + index === 0 ? startDy : lineHeight
              const text = line.words.join("  ")
              const isBlankLine = text.length === 0

              return isBlankLine ? (
                <tspan key={index} x={x} dy={dy} visibility="hidden">
                  .
                </tspan>
              ) : (
                <tspan key={index} x={x} dy={dy}>
                  {text}
                </tspan>
              )
            })}
          </React.Fragment>
        )
      })}
    </text>
  )
}

export default SvgText
