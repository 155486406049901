import { EntityId } from "@jackfruit/common"

// shared context to allow saga functions to get the
// current page id as soon as possible without awaiting for
// useEffect to trigger which can cause sync issues for some components
// when navigating from one page to the other
let currentPageId: EntityId = 0

export const getCurrentPageId = () => currentPageId
export const setCurrentPageId = (newPageId: EntityId) => {
  currentPageId = newPageId
}
