import { replace } from "@wordpress/shortcode"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  text: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("link", text, args => {
    const field = args.attrs.named.field
    const errorMessage = `[link error="property {${field}} not found"]`

    const client = state.client!
    if (!client) {
      return errorMessage
    }

    switch (field) {
      case "supportUrl":
        return client.supportUrl ?? ""
      case "supportEmail":
        return client.supportEmail ?? ""
      default:
        return errorMessage
    }
  })
}
