import {
  EntityId,
  PrinticularOrder,
  PrintServiceProductEntity,
  PrintServiceProductPriceEntity,
} from "@jackfruit/common"
import { createAction, PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { call, getContext, put, select, takeEvery } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import {
  GA4AddToCart,
  GA4BeginCheckout,
  GA4CouponApplied,
  GA4CouponInvalid,
  GA4Login,
  GA4Purchase,
  GA4RemoveFromCart,
} from "~/services/GA4"
import { cartsActions, cartsSelector } from "../state/carts"
import { orderSummariesSelector } from "../state/orderSummaries"
import { pageSessionsSelector } from "../state/pageSessions"
import { RootState } from "../store"
import { getLineItem } from "./lineItems"
import { getPrintServiceProductPrice } from "./printServiceProductPrices"
import { getPrintServiceProduct } from "./printServiceProducts"

type InAppEventType =
  | "reset"
  | "addOneItemToCart" // add one item or increase quantity by one
  | "duplicateItemInCart" // duplicate a set of items of the same product
  | "removeOneItemFromCart" // decrease the quantity by one
  | "clearItemFromCart" // completely delete a line item with all its items
  | "replaceProductInCart"
  | "purchase"
  | "beginCheckout"
  | "couponApplied"
  | "couponInvalid"
  | "login"

interface ResetPayload {}

interface AddOneItemToCartData {
  lineItemId: EntityId
  productId?: EntityId
}

interface RemoveOneItemFromCartData {
  lineItemId: EntityId
  productId?: EntityId
}

interface DuplicateItemInCartData {
  lineItemId: EntityId
}

interface ReplaceProductInCart {
  lineItemId: EntityId
  oldProductId: EntityId
  newProductId: EntityId
  oldQuantity: number
  newQuantity: number
}

interface ClearItemFromCartData {
  lineItemId: EntityId
}

interface PurchaseData {
  order: PrinticularOrder
  store: StoreEntity
}

interface CouponAppliedData {
  order: PrinticularOrder
  store: StoreEntity
}

interface CouponInvalidData {
  order: PrinticularOrder
  store: StoreEntity
}

interface BeginCheckoutData {}

interface LoginData {}

export interface AnalyticEventPayload {
  eventType: InAppEventType
  data:
    | AddOneItemToCartData
    | RemoveOneItemFromCartData
    | DuplicateItemInCartData
    | ClearItemFromCartData
    | PurchaseData
    | BeginCheckoutData
    | ReplaceProductInCart
    | ResetPayload
    | LoginData
}

export const AnalyticAction = createAction<AnalyticEventPayload>(
  "process/AnalyticEvent"
)

export function* watchAnalyticEvents() {
  yield takeEvery(AnalyticAction.type, processAnalyticEvents)
}

interface GetLineItemDataReturn {
  product: PrintServiceProductEntity
  price: PrintServiceProductPriceEntity
  quantity: number
}

function* getLineItemData({
  lineItemId,
  productId,
}: {
  lineItemId: EntityId
  productId?: EntityId
}): SagaIterator<GetLineItemDataReturn> {
  let quantity = 1
  let productIdToUse = productId
  // If we don't have a product ID, we need to get it from the line item
  if (!productIdToUse) {
    const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
    productIdToUse = lineItem.productId
    quantity = lineItem.quantity
  }

  const product: PrintServiceProductEntity = yield call(
    getPrintServiceProduct,
    productIdToUse
  )
  const priceId: EntityId = product.prices[0]
  const price = yield call(getPrintServiceProductPrice, priceId)

  return { product, price, quantity }
}

function* addOneItemToCart(data: AddOneItemToCartData): SagaIterator<void> {
  const { lineItemId, productId } = data
  const { product, price }: GetLineItemDataReturn = yield call(
    getLineItemData,
    {
      lineItemId,
      productId,
    }
  )

  GA4AddToCart(product, price, 1)
}

function* removeOneItemFromCart(data: RemoveOneItemFromCartData): SagaIterator {
  const { lineItemId, productId } = data
  const { product, price }: GetLineItemDataReturn = yield call(
    getLineItemData,
    {
      lineItemId,
      productId,
    }
  )

  GA4RemoveFromCart(product, price, 1)
}

function* duplicateItemInCart(data: DuplicateItemInCartData): SagaIterator {
  const { lineItemId } = data
  const { product, price, quantity }: GetLineItemDataReturn = yield call(
    getLineItemData,
    { lineItemId }
  )

  GA4AddToCart(product, price, quantity)
}

function* clearItemFromCart(data: ClearItemFromCartData): SagaIterator {
  const { lineItemId } = data
  const { product, price, quantity }: GetLineItemDataReturn = yield call(
    getLineItemData,
    { lineItemId }
  )

  GA4RemoveFromCart(product, price, quantity)
}

function* replaceProductInCart(data: ReplaceProductInCart): SagaIterator {
  const { lineItemId, newProductId, newQuantity, oldProductId, oldQuantity } =
    data

  const { product: oldProduct, price: oldPrice }: GetLineItemDataReturn =
    yield call(getLineItemData, {
      lineItemId,
      productId: oldProductId,
    })

  const { product: newProduct, price: newPrice }: GetLineItemDataReturn =
    yield call(getLineItemData, {
      lineItemId,
      productId: newProductId,
    })

  GA4RemoveFromCart(oldProduct, oldPrice, oldQuantity)
  GA4AddToCart(newProduct, newPrice, newQuantity)
}

function* purchase(data: PurchaseData): SagaIterator {
  const { order, store } = data
  GA4Purchase(order, store)
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()
  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )
  yield put(cartsActions.finishCheckout(pageCart.id))
}

function* beginCheckout(): SagaIterator {
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()
  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, pageSession.cartId)
  )

  if (!pageCart.hasStartedCheckout) {
    const order: OrderSummaryEntity = yield select((state: RootState) =>
      orderSummariesSelector.selectById(state, pageSession.orderSummaryId)
    )

    yield put(cartsActions.startCheckout(pageCart.id))

    GA4BeginCheckout(order.data as PrinticularOrder)
  }
}

function couponApplied(data: CouponAppliedData) {
  const { order, store } = data

  GA4CouponApplied(order, store)
}

function couponInvalid(data: CouponInvalidData) {
  const { order, store } = data

  GA4CouponInvalid(order, store)
}

function login(_data: LoginData) {
  GA4Login()
}

export function* processAnalyticEvents(
  action: PayloadAction<AnalyticEventPayload>
) {
  const { eventType, data } = action.payload

  switch (eventType) {
    case "addOneItemToCart":
      yield call(addOneItemToCart, data as AddOneItemToCartData)
      break
    case "duplicateItemInCart":
      yield call(duplicateItemInCart, data as DuplicateItemInCartData)
      break
    case "removeOneItemFromCart":
      yield call(removeOneItemFromCart, data as RemoveOneItemFromCartData)
      break
    case "clearItemFromCart":
      yield call(clearItemFromCart, data as ClearItemFromCartData)
      break
    case "replaceProductInCart":
      yield call(replaceProductInCart, data as ReplaceProductInCart)
      break
    case "beginCheckout":
      yield call(beginCheckout)
      break
    case "purchase":
      yield call(purchase, data as PurchaseData)
      break
    case "couponApplied":
      yield call(couponApplied, data as CouponAppliedData)
      break
    case "couponInvalid":
      yield call(couponInvalid, data as CouponInvalidData)
      break
    case "login":
      yield call(login, data as LoginData)
      break
  }
}
