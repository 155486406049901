import {
  Button,
  Flex,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { CountrySelectorConfiguration } from "@jackfruit/common"
import React from "react"
import { FiChevronDown, FiGlobe } from "react-icons/fi"

interface Props {
  options: CountrySelectorConfiguration[]
}

const FooterBlockCountrySelector: React.FC<Props> = ({ options }) => {
  const value = options[0].label
  return (
    <Menu>
      <MenuButton
        className="p-footer-country-selector"
        name="p-footer-country-selector"
        as={Button}
        colorScheme="grey"
        bg="white"
        variant="ghost"
      >
        <Flex justifyContent="center" alignItems="center">
          <Icon as={FiGlobe} mr={2} /> {value}{" "}
          <Icon as={FiChevronDown} ml={2} />
        </Flex>
      </MenuButton>
      <MenuList className="country-selector-options" py={0}>
        {options.map((option, index) => {
          const isFirst = index === 0
          const isLast = index === options.length - 1
          return (
            <MenuItem
              className={`country-option-${index + 1}`}
              borderTopRadius={isFirst ? 5 : 0}
              borderBottomRadius={isLast ? 5 : 0}
              as={ChakraLink}
              key={option.label}
              isDisabled={option.label === value}
              href={option.url}
              rel="noopener"
              isExternal
            >
              {option.label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default FooterBlockCountrySelector
