import { PageEntity } from "@jackfruit/common"
import { EntityId } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { pagesSelector } from "~/redux/state/pages"
import { RootState } from "~/redux/store"
import { useCurrentPageId } from "./useCurrentPageId"

export const usePage = (id?: EntityId) => {
  const currentPageId = useCurrentPageId()

  const page = useSelector<RootState, PageEntity>(
    state => pagesSelector.selectById(state, id || currentPageId)!
  )

  return { page }
}
