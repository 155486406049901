import { ApiException } from "~/interfaces/entities/ApiException"
import { t } from "i18next"
// The codes are provided by the API Server,
// and not directly from the payment provider
const PAYMENT_EXCEPTION_CODES = [
  // Stripe payment failures
  "1070",
  "1071",
  "1072",
  "1073",
  "1074",
  "1075",
  "1076",
  "1077",
  "10036",

  // Simply Blu payment failures
  "1060",
  "1061",
  "1062",

  // PayTM Payment failures
  "1058",
  "1059",

  // Generic could not process payment
  "1116",
]

const ORDER_PLACEMENT_EXCEPTION_CODES = [
  // Print service validation errors
  "3000",
  "3001",
  "3010",
  "3011",
  "3020",
  "3021",
  "3030",
  "3040",
  "3050",
  "3055",
  "3060",
  "3100",
  "3200",
  "3300",
  "3400",
  "3999",

  // Order limit error
  "8000",

  // No line items
  "8005",

  // Too many prints
  "8007",

  // Line item quantity
  "1999",

  // Line item image counts
  "1101",
  "1102",
  "1103",
  "1104",

  // Purchasing gift card with coupon
  "1105",
].concat(PAYMENT_EXCEPTION_CODES)

const NONCE_ALREADY_USED_CODE = "1903"

const AUTH_EXCEPTION_CODES = [
  "6001",
  "3012",
  "10016", // Session expired
]

const MAINTENANCE_EXCEPTION_CODES = ["999901"]

const getExceptionCode = (exception: ApiException) => {
  const { code: exceptionCode } = exception
  // The ugly code below is such that we only deal with
  // exception codes that aren't null, undefined or objects
  if (!["string", "number"].includes(typeof exceptionCode)) {
    return ""
  }

  return exceptionCode.toString().trim()
}

const getOrderLimitAsString = (message: string): string => {
  //Retrieve the first number in error message
  const orderLimit = message.match(/\d+/)?.[0]

  return orderLimit ?? "(unknown limit)"
}

export const getExceptionDisplayedMessage = (
  exception: ApiException
): string => {
  // Payment errors
  if (isFailedPayment(exception)) {
    return exception.message
  }

  const exceptionCode = getExceptionCode(exception)

  switch (exceptionCode) {
    // Order limits
    case "1999":
      return t("services.ApiExceptionHelper.OrderLimits", {
        amount: getOrderLimitAsString(exception.message),
      })
    case "8007":
      return t("services.ApiExceptionHelper.OrderLimitsTwo", {
        amount: getOrderLimitAsString(exception.message),
      })
    case "8000": //TODO might need currency symbol in the future
      return t("services.ApiExceptionHelper.ValueLimits", {
        amount: getOrderLimitAsString(exception.message),
      })
    // Auth fail
    case "6001":
      return t("services.ApiExceptionHelper.AuthFail")
    default:
      return exception.message
  }
}

export const isFailedPayment = (exception: ApiException): boolean => {
  const exceptionCode = getExceptionCode(exception)

  return PAYMENT_EXCEPTION_CODES.includes(exceptionCode)
}

export const isAuthFailed = (exception: ApiException): boolean => {
  const exceptionCode = getExceptionCode(exception)

  return AUTH_EXCEPTION_CODES.includes(exceptionCode)
}

// Intended to capture all order placement errors, including payment errors
export const isOrderPlacementFailed = (exception: ApiException): boolean => {
  const exceptionCode = getExceptionCode(exception)

  return ORDER_PLACEMENT_EXCEPTION_CODES.includes(exceptionCode)
}

export const isMaintenance = (exception: ApiException): boolean => {
  const exceptionCode = getExceptionCode(exception)

  return MAINTENANCE_EXCEPTION_CODES.includes(exceptionCode)
}

export const isOrderAlreadyPlaced = (exception: ApiException): boolean => {
  const exceptionCode = getExceptionCode(exception)

  return exceptionCode === NONCE_ALREADY_USED_CODE
}

export const isInternalServerError = ({
  errors = [],
}: ApiException): boolean => {
  const status = (errors[0]?.status ?? "").toString().trim()

  return status.startsWith("5")
}
