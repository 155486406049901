import { EntityId } from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { select } from "redux-saga/effects"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { imageRegionsSelectors } from "../state/imageRegions"
import { RootState } from "../store"

export function* getImageRegion(
  imageRegionId: EntityId
): SagaIterator<ImageRegionEntity> {
  const imageRegion: ImageRegionEntity = yield select((state: RootState) =>
    imageRegionsSelectors.selectById(state, imageRegionId)
  )

  return imageRegion
}
