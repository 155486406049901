import { select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { ApplicationState } from "../state/application"
import { RootState } from "../store"

export function* getApplication(): SagaIterator<ApplicationState> {
  const application: ApplicationState = yield select(
    (state: RootState) => state.application
  )

  return application
}
