import { SeverityLevel, CaptureContext } from "@sentry/types"
import * as Sentry from "@sentry/browser"

interface LoggerHandler {
  [key: string]: (...arg: any[]) => void
}
interface LoggerPayload {
  level: SeverityLevel
  message: any
  logToSentry?: boolean
}
type LoggerParams = string | LoggerPayload

const logHandler = (
  level: SeverityLevel,
  logToSentry: boolean = false,
  ...args: LoggerParams[]
) => {
  const message: any =
    args.length === 1 && typeof args[0] === "string" ? args.unshift() : args
  const captureContext: CaptureContext = {
    level,
    extra: { ...args },
  }

  if (window && window.debug) {
    const handler: LoggerHandler = {
      fatal: console.error,
      error: console.error,
      warning: console.warn,
      info: console.info,
      debug: console.debug,
    }

    if (handler[level]) {
      handler[level](message, ...args)
    } else {
      console.log(message, ...args)
    }
  }

  if (logToSentry) {
    switch (level) {
      case "error":
      case "fatal":
      case "warning":
        return Sentry.captureException(Error(message), captureContext)
      default:
        return Sentry.captureMessage(message, captureContext)
    }
  }
}

const error = (...args: any[]) => {
  return logHandler("info", true, ...args)
}

const warning = (...args: any[]) => {
  return logHandler("warning", true, ...args)
}

const debug = (...args: any[]) => {
  return logHandler("debug", true, ...args)
}

const info = (...args: any[]) => {
  return logHandler("info", false, ...args)
}

const log = (...args: any[]) => {
  return logHandler("log", false, ...args)
}

export const logger = {
  error,
  warning,
  debug,
  info,
  log,
}
