import { Box, Flex, Heading } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const FooterBlockMegamenuHtml: React.FC<Props> = ({ cell }) => {
  const { html, title } = cell

  return (
    <Flex flexDirection="column">
      {Boolean(title) && (
        <Heading as="h4" size="sm" mb={4}>
          {title}
        </Heading>
      )}
      <Box
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Flex>
  )
}

export default FooterBlockMegamenuHtml
