import { Box } from "@chakra-ui/layout"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import FooterBlock from "../../../components/blocks/FooterBlock"

interface Props {
  config: AppEntityHydrated
}

const Footer: React.FC<Props> = ({ config }) => {
  const blockConfig = config.pages[0].blocks.find(
    (block: any) => block.type === "footer"
  )?.footer!

  return (
    <Box className="block-footer">
      <FooterBlock config={blockConfig} appConfig={config} />
    </Box>
  )
}

export default Footer
