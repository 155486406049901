import { AppEntityHydrated, BlockHeaderEntity } from "@jackfruit/common"
import { useBlocks } from "./useBlocks"
import { usePage } from "./usePage"

export const useSiteWideBanner = (
  config: BlockHeaderEntity,
  appConfig: AppEntityHydrated
) => {
  const { displaySiteWideBanner, siteWideBannerContent } = config
  const { termsAndConditionsPageId, privacyPolicyPageId } = appConfig

  const { page } = usePage()

  const { blocks } = useBlocks(page?.blocks)

  if (!page) {
    return {
      displaySiteWideBanner: false,
      siteWideBannerContent: "",
    }
  }

  const isOrderSuccessPage = blocks.some(
    block => block.type === "order-thank-you" || block.type === "order-complete"
  )

  const isLegalsPage =
    page?.id === termsAndConditionsPageId || page?.id === privacyPolicyPageId

  const otherConditions = !isOrderSuccessPage && !isLegalsPage

  return {
    displaySiteWideBanner: displaySiteWideBanner && otherConditions,
    siteWideBannerContent: siteWideBannerContent,
  }
}
