import { Box, Fade, Flex, Link, Text, useDisclosure } from "@chakra-ui/react"
import { NavigationMegaMenuEntityHydrated } from "@jackfruit/common"
import React, { useEffect, useState } from "react"
import { useIdealMenuPosition } from "./hooks/useIdealMenuPosition"
import MegaMenuColumn from "./MegaMenuColumn"

interface Props {
  megamenu: NavigationMegaMenuEntityHydrated
  label: string
  color: string
  colorHover?: string
}

const MegaMenu: React.FC<Props> = ({ megamenu, label, color, colorHover }) => {
  const { id, columns } = megamenu

  const { menuItemRef, menuPanelRef, position } = useIdealMenuPosition()

  const [isOverLabel, setIsOverLabel] = useState(false)
  const [isOverMenu, setIsOverMenu] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleMouseEnterLabel = () => {
    setIsOverLabel(true)
  }

  const handleMouseLeaveLabel = () => {
    setTimeout(() => {
      setIsOverLabel(false)
    }, 100)
  }

  const handleMouseEnterMenu = () => {
    setIsOverMenu(true)
  }

  const handleMouseLeaveMenu = () => {
    setIsOverMenu(false)
  }

  useEffect(() => {
    if (!isOpen && (isOverLabel || isOverMenu)) {
      onOpen()
    } else if (isOpen && !isOverLabel && !isOverMenu) {
      onClose()
    }
  }, [isOpen, isOverLabel, isOverMenu, onClose, onOpen])

  return (
    <Box>
      <Box
        ref={menuItemRef}
        p={4}
        onMouseEnter={handleMouseEnterLabel}
        onMouseLeave={handleMouseLeaveLabel}
        _hover={{ cursor: "pointer" }}
      >
        <Text
          id={`megamenu-${id}`}
          as={Link}
          fontWeight="normal"
          color={isOpen ? colorHover : color}
          textDecoration={isOpen ? "underline" : "none"}
        >
          {label}
        </Text>
      </Box>

      <Box
        ref={menuPanelRef}
        zIndex={10}
        position="absolute"
        onMouseEnter={handleMouseEnterMenu}
        onMouseLeave={handleMouseLeaveMenu}
        {...(!isOpen ? { top: -10000 } : {})}
        {...position}
      >
        <Fade in={isOpen}>
          <Box
            backgroundColor="white"
            borderRadius="md"
            borderBottomLeftRadius="md"
            borderBottomRightRadius="md"
            boxShadow="md"
            borderWidth={1}
            py={4}
            mt="6px"
          >
            <Flex justifyContent="center">
              {columns.map((column, index) => {
                const isLast = index === columns.length - 1
                return (
                  <MegaMenuColumn
                    key={column.id}
                    column={column}
                    isLast={isLast}
                  />
                )
              })}
            </Flex>
          </Box>
        </Fade>
      </Box>
    </Box>
  )
}

export default MegaMenu
