import { Entity, EntityId } from "@jackfruit/common"
import { ImageRegionEntity } from "./ImageRegion"
import { TextRegionEntity } from "./TextRegion"

export enum PageType {
  FrontCover = "front-cover",
  FrontCoverInside = "front-cover-inside",
  Page = "page",
  BlankPage = "blank-page",
  BackCoverInside = "back-cover-inside",
  BackCover = "back-cover",
}

export const PageTypeToSkipForUpload = [
  PageType.FrontCover,
  PageType.FrontCoverInside,
  PageType.BackCoverInside,
  PageType.BackCover,
]

export interface ProductPageEntity extends Entity {
  pageType: PageType
  name?: string
  imageRegionIds: EntityId[]
  textRegionIds: EntityId[]
  width: number
  height: number
  svg?: string
  seeThrough?: boolean
  isCustomCover?: boolean
}

export interface ProductPageEntityHydrated
  extends Omit<
    ProductPageEntity,
    "imageRegionIds" | "textRegionIds" | "productImageIds"
  > {
  imageRegions: ImageRegionEntity[]
  textRegions: TextRegionEntity[]
}
