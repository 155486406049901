import { Flex } from "@chakra-ui/react"
import { NavigationMegaMenuColumnEntityHydrated } from "@jackfruit/common"
import React from "react"
import MegaMenuCell from "./MegaMenuCell"

interface Props {
  column: NavigationMegaMenuColumnEntityHydrated
  isLast: boolean
}

const MegaMenuColumn: React.FC<Props> = ({ column, isLast }) => {
  const { cells } = column

  return (
    <Flex
      direction="column"
      borderRightWidth={!isLast ? 1 : 0}
      gridGap={12}
      p={4}
      flex={1}
      width="220px"
    >
      {cells.map(cell => {
        return <MegaMenuCell key={cell.id} cell={cell} />
      })}
    </Flex>
  )
}

export default MegaMenuColumn
