import {
  BlockEntityHydrated,
  EntityId,
  FulfillmentTypes,
  PageEntity,
  PrintServiceEntity,
  PrintServiceEntityHydrated,
} from "@jackfruit/common"
import { call, select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import { printServicesSelector } from "../state/printServices"
import { RootState } from "../store"
import { getBlock } from "./blocks"
import { getCart } from "./cart"
import { getCurrentPage, getPage } from "./page"
import { getPageSession } from "./pageSession"
import { getStore } from "./store"

export function* getCurrentPrintServices(): SagaIterator<
  PrintServiceEntityHydrated[]
> {
  const page: PageEntity = yield call(getCurrentPage)

  const printServiceDataForPickup = page.printServices.pickup ?? []
  const printServiceDataForDelivery = page.printServices.delivery ?? []

  const printServiceIds: EntityId[] = [
    ...printServiceDataForPickup.map(p => p.id),
    ...printServiceDataForDelivery.map(p => p.id),
  ]

  const printServices = yield select((state: RootState) =>
    printServicesSelector.selectByIds(state, printServiceIds)
  )

  return printServices
}

export function* getPrintService(payload: {
  id: EntityId
}): SagaIterator<PrintServiceEntity> {
  return yield select((state: RootState) =>
    printServicesSelector.selectById(state, payload.id)
  )
}

export function* getCurrentPrintServicesByFulfillment(payload: {
  fulfillment: FulfillmentTypes
}): SagaIterator<PrintServiceEntityHydrated[]> {
  const { fulfillment } = payload

  const printServices: PrintServiceEntityHydrated[] = yield call(
    getCurrentPrintServices
  )

  return printServices.filter(p => p.fulfillmentType === fulfillment)
}

export function* getPrintServiceByRemoteId(payload: {
  remoteId: EntityId
}): SagaIterator<PrintServiceEntityHydrated | undefined> {
  const { remoteId } = payload

  const printServices: PrintServiceEntityHydrated[] = yield call(
    getCurrentPrintServices
  )

  return printServices.find(p => p.remoteId === remoteId)
}

export function* getCurrentPagePrintServiceForFulfillment(payload: {
  fulfillment: FulfillmentTypes
}): SagaIterator<PrintServiceEntity> {
  const { fulfillment } = payload

  const page: PageEntity = yield call(getCurrentPage)
  const printServiceId = page.printServices[fulfillment][0].id

  const printService: PrintServiceEntity = yield select((state: RootState) =>
    printServicesSelector.selectById(state, printServiceId)
  )

  return printService
}

export function* getPagePrintServiceForFulfillment(payload: {
  fulfillment: FulfillmentTypes
  pageId: EntityId
}): SagaIterator<PrintServiceEntity> {
  const { pageId, fulfillment } = payload
  const page: PageEntity = yield call(getPage, { pageId })
  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: pageId,
  })
  const cart: CartEntity = yield call(getCart, pageSession.cartId)

  let printServiceId = page.printServices[fulfillment][0].id

  // if current store is selected and entity is present in store
  // then printService should be the store print service
  // otherwise just fallback to the first available print service for the
  // target fulfillment
  if (
    pageSession.pageFlow === "store-first" &&
    pageSession.hasLoadedStores &&
    fulfillment === "pickup"
  ) {
    const storeId = cart.storeId

    const selectedStore: StoreEntity = yield call(getStore, { storeId })
    if (selectedStore) {
      printServiceId = selectedStore.printServiceId
    }
  }

  const printService: PrintServiceEntity = yield select((state: RootState) =>
    printServicesSelector.selectById(state, printServiceId)
  )

  return printService
}

export function* getBlockPrintServiceForFulfillment(payload: {
  fulfillment: FulfillmentTypes
  blockId: EntityId
}): SagaIterator<PrintServiceEntity> {
  const { blockId, fulfillment } = payload

  const block: BlockEntityHydrated = yield call(getBlock, { blockId })
  // TODO could use block[block.type] instead, but it requires reworking the entity interfaces
  const printServiceId = block.template.printServices[fulfillment][0].id

  const printService: PrintServiceEntity = yield select((state: RootState) =>
    printServicesSelector.selectById(state, printServiceId)
  )

  return printService
}

export function* getAllPrintServices(): SagaIterator<PrintServiceEntity[]> {
  const printServices: PrintServiceEntity[] = yield select(
    printServicesSelector.selectAll
  )

  return printServices
}
