import { Box } from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { replaceShortCodes } from "~/services/replaceShortCodes"

interface Props {
  html: string
  config: AppEntityHydrated
}

const HtmlContent: React.FC<Props> = ({ html, config }) => {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: replaceShortCodes(html, { appConfig: config }),
      }}
    />
  )
}

export default HtmlContent
