import { TerritoryEntityHydrated } from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"
import { RootState } from "../store"

const { slice: territories, selectors } = createEntitySlice<
  TerritoryEntityHydrated
>("territories", { selectId: t => t.code })

const selectCountryByCode = (state: RootState, code: string) =>
  territoriesSelectors
    .selectAll(state)
    .find(territory => territory.code === code)

const territoriesSelectors = {
  ...selectors,
  selectCountryByCode,
}

export { territories, territoriesSelectors }
