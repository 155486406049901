import { useToast } from "@chakra-ui/react"
import { navigate } from "gatsby-link"
import { useDispatch, useSelector } from "react-redux"
import { AuthUser, AuthUserInfos } from "~/interfaces/entities/AuthUser"
import { auth, AuthState, LoginSuccessPayload } from "~/redux/state/auth"
import { RootState } from "~/redux/store"

export const useAuth = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const state = useSelector<RootState, AuthState>(
    (state: RootState) => state.auth
  )

  const actions = {
    login: (payload: { login: string; password: string }) => {
      dispatch(
        auth.actions.login({
          ...payload,
          toast,
        })
      )
    },
    logout: () => dispatch(auth.actions.logout({ toast })),
    loginSuccess: (authUser: AuthUser, redirect = true) => {
      const payload: LoginSuccessPayload = {
        authUser,
      }

      if (redirect) {
        payload.navigate = navigate
      }

      dispatch(auth.actions.loginSuccess(payload))
    },
    setUserInfos: (payload: AuthUserInfos) =>
      dispatch(auth.actions.setUserInfos(payload)),
  }

  return { state, ...actions }
}
