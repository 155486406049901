import { ClientPrintServiceEntity, EntityId } from "@jackfruit/common"
import { createSelector } from "@reduxjs/toolkit"
import { createEntitySlice } from "../createEntitySlice"
import { RootState } from "../store"

const { slice: clientPrintServices, selectors } =
  createEntitySlice<ClientPrintServiceEntity>("clientPrintServices")

const selectByRemotePrintServiceId = (
  state: RootState,
  remotePrintServiceId: EntityId
) =>
  selectors
    .selectAll(state)
    .find(
      clientPrintService =>
        clientPrintService.printService === remotePrintServiceId
    )

const makeSelectByRemotePrintServiceId = () => {
  return createSelector(
    [selectByRemotePrintServiceId],
    clientPrintServices => clientPrintServices
  )
}

const clientPrintServicesSelectors = {
  ...selectors,
  makeSelectByRemotePrintServiceId,
}

export { clientPrintServices, clientPrintServicesSelectors }
