import {
  PrinticularProductTemplateEntity,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateTagEntity,
} from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"

export const { slice: productTemplates, selectors: productTemplateSelectors } =
  createEntitySlice<PrinticularProductTemplateEntity>("productTemplates")

export const {
  slice: productTemplateCategories,
  selectors: productTemplateCategorySelectors,
} = createEntitySlice<PrinticularProductTemplateCategoryEntity>(
  "productTemplateCategories"
)

export const {
  slice: productTemplateTags,
  selectors: productTemplateTagSelectors,
} = createEntitySlice<PrinticularProductTemplateTagEntity>(
  "productTemplateTags"
)
