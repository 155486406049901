import chroma from "chroma-js"
import { getFallbackTextColor } from "~/services/Utils"

export const getColorScales = (color: string) => {
  return {
    50: chroma(color).brighten(1).hex(),
    100: chroma(color).brighten(0.8).hex(),
    200: chroma(color).brighten(0.6).hex(),
    300: chroma(color).brighten(0.4).hex(),
    400: chroma(color).brighten(0.2).hex(),
    500: color,
    600: chroma(color).darken(0.2).hex(),
    700: chroma(color).darken(0.4).hex(),
    800: chroma(color).darken(0.6).hex(),
    900: chroma(color).darken(0.8).hex(),
    text: getFallbackTextColor(color),
  }
}
