import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { blocksSelector } from "~/redux/state/blocks"
import { RootState } from "~/redux/store"
import { usePage } from "./usePage"

export const useBlocks = (ids?: EntityId[]) => {
  const { page } = usePage()
  const blockIds = page?.blocks

  // Use blocks will return blocks for the current page if no id is provided
  const blocks = useSelector((state: RootState) =>
    blocksSelector.selectByIds(state, ids ?? blockIds)
  )

  return { blocks }
}
