import { PrinticularProductTemplateEntity } from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { actions, UpdateLineItemProductTemplateVariant } from "../process"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { productTemplateSelectors } from "../state/productTemplates"
import { RootState } from "../store"
import { updateProductPages } from "./templateHelpers"

export default function* watchUpdateLineItemProductTemplateVariant() {
  yield takeEvery(
    actions.updateLineItemProductTemplateVariant.type,
    processUpdateLineItemProductTemplateVariant
  )
}

function* processUpdateLineItemProductTemplateVariant(
  action: PayloadAction<UpdateLineItemProductTemplateVariant>
): SagaIterator<any> {
  const { lineItemId, productTemplateVariantId } = action.payload

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  const productTemplate: PrinticularProductTemplateEntity = yield select(
    (state: RootState) =>
      productTemplateSelectors.selectById(state, lineItem.productTemplateId!)
  )

  const variant = productTemplate.variants.find(
    variant => variant.key === productTemplateVariantId
  )!
  const firstPage = variant.pages[0]

  const orientation =
    firstPage.width > firstPage.height ? "landscape" : "portrait"

  yield call(updateProductPages, {
    productPageIds: lineItem.productPageIds,
    variant,
  })

  yield put(
    lineItems.actions.updateOne({
      id: lineItemId,
      changes: {
        productTemplateVariantId,
        orientation,
      },
    })
  )
}
