import { replace } from "@wordpress/shortcode"
import { t } from "i18next"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"

const getDayOrEveningString = () => {
  const current = new Date()
  //Between 17:00 and 24:00 say evening?
  return current.getHours() >= 17 && current.getHours() < 24
    ? t("services.shortCodeParsers.order.Evening")
    : t("services.shortCodeParsers.order.Day")
}

export const parser: ShortCodeParser = (
  text: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("order", text, args => {
    const { order: orderState } = state
    const appConfig = state.appConfig!
    const field = args.attrs.named.field ?? ""

    switch (field) {
      case "appName":
        return appConfig.name
      case "appDisplayName":
        return appConfig.displayName
      case "dayOrEvening":
        return getDayOrEveningString()
    }

    // equivalent to state[field], but make typescript happy as 'field' may not exist
    if (field) {
      for (const [key, value] of Object.entries(orderState!)) {
        if (key === field) {
          return value
        }
      }
    }

    return `[order error="property {${field}} not found"]`
  })
}
