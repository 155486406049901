import { EntityId, StripePaymentIntent } from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { CartEntity } from "~/interfaces/entities/Cart"
import { createEntitySlice } from "../createEntitySlice"

const { slice: carts, selectors: cartsSelector } =
  createEntitySlice<CartEntity>("carts")

export const cartsFactory = (data: Partial<CartEntity>): CartEntity => {
  return {
    id: nanoid(),
    couponCode: "",
    couponCodeStatus: "pending",
    couponCodeTitle: "",
    couponType: "coupon",
    fulfillment: "delivery",
    lineItemIds: [],
    payment: {
      type: null,
      data: null,
    },
    printServiceId: 0,
    storeId: 0,
    storePlace: "",
    storeLat: 0,
    storeLng: 0,
    hasStartedCheckout: false,
    nonce: "",
    retailerIds: data.retailerIds || null,
    paymentIntent: undefined,
    ...data,
  }
}

const cartsActions = {
  delete: (id: EntityId) => {
    return carts.actions.removeOne(id)
  },
  finishCheckout: (id: EntityId) => {
    return carts.actions.updateOne({
      id,
      changes: {
        hasStartedCheckout: false,
      },
    })
  },
  startCheckout: (id: EntityId) => {
    return carts.actions.updateOne({
      id,
      changes: {
        hasStartedCheckout: true,
      },
    })
  },
  setPaymentIntent: (
    id: EntityId,
    paymentIntent: StripePaymentIntent | undefined
  ) => {
    return carts.actions.updateOne({
      id,
      changes: {
        paymentIntent,
      },
    })
  },
}

export { carts, cartsActions, cartsSelector }
