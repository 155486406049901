import { PrintServiceProductEntity } from "@jackfruit/common"
import React from "react"
import ProductPageRenderer, { RendererPage } from "./ProductPageRenderer"

interface Props {
  product: PrintServiceProductEntity
  page: RendererPage
  forEditorDisplay?: boolean
}

const ProductPageFoldedCardRenderer: React.FC<Props> = ({
  page,
  product,
  forEditorDisplay = true,
}) => {
  const productWidth = product?.pixelWidth ?? 0
  const productHeight = product?.pixelHeight ?? 0
  const pageWidth = page.viewBox.width
  const pageHeight = page.viewBox.height

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${productWidth} ${productHeight}`}
    >
      <svg
        viewBox={`0 0 ${pageWidth} ${pageHeight}`}
        preserveAspectRatio="xMidYMin slice"
      >
        <ProductPageRenderer page={page} forEditorDisplay={forEditorDisplay} />
      </svg>
    </svg>
  )
}

export default ProductPageFoldedCardRenderer
