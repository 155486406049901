import {
  EntityId,
  FulfillmentTypes,
  PrintServiceProductEntity,
} from "@jackfruit/common"
import { uniq } from "lodash"
import { SagaIterator } from "redux-saga"
import { call, put } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import {
  ProductSwap,
  ProductSwapMap,
} from "~/interfaces/entities/ProductSwapMap"
import {
  getCompatibleProducts,
  getMostCompatibleProduct,
} from "~/services/Utils"
import { actions } from "../process"
import { getCart, getCartLineItems, getCurrentCart } from "./cart"
import { getCurrentPageSession, getPageSession } from "./pageSession"
import {
  getBlockProductsForFulfillment,
  getPageProductsForFulfillment,
  getPrintServiceProducts,
  getProductsForStore,
} from "./printServiceProducts"
import { getProductTemplateVariantForProduct } from "./productTemplateVariants"

export function* checkProductsCompatibility(payload: {
  productSwapMap: ProductSwapMap
}): SagaIterator<{
  lineItemsToKeep: EntityId[]
  lineItemsToRemove: EntityId[]
}> {
  const { productSwapMap } = payload
  const lineItems: LineItemEntity[] = yield call(getCartLineItems)

  const lineItemsToKeep: EntityId[] = []
  const lineItemsToRemove: EntityId[] = []

  // Check if all line items has replacement ids
  for (const lineItem of lineItems) {
    if (!lineItem.isReady) {
      lineItemsToKeep.push(lineItem.id)
      continue
    }

    const replacementProductId = yield call(getReplacementProductId, {
      productSwapMap,
      lineItem,
    })

    if (replacementProductId) {
      lineItemsToKeep.push(lineItem.id)
      continue
    }

    lineItemsToRemove.push(lineItem.id)
  }
  return { lineItemsToKeep, lineItemsToRemove }
}

export function* applyProductSwapMap(payload: {
  productSwapMap: ProductSwapMap
}): SagaIterator {
  const { productSwapMap } = payload
  const lineItems: LineItemEntity[] = yield call(getCartLineItems)

  for (const lineItem of lineItems) {
    const lineItemId = lineItem.id
    if (!lineItem.isReady) {
      continue
    }
    const replacementProductId = yield call(getReplacementProductId, {
      productSwapMap,
      lineItem,
    })
    if (replacementProductId === null) {
      yield put(actions.removeLineItem({ lineItemId }))
    } else {
      yield put(
        actions.updateLineItemProduct({
          lineItemId,
          productId: replacementProductId,
          event: "fulfillmentChanged",
        })
      )
    }
  }
}

function* getReplacementProductId(payload: {
  productSwapMap: ProductSwapMap
  lineItem: LineItemEntity
}): SagaIterator<EntityId | null> {
  const { productSwapMap, lineItem } = payload

  let replacementProductId = null
  if (lineItem.blockId) {
    replacementProductId =
      productSwapMap.blocks[lineItem.blockId][lineItem.productId]
  } else {
    replacementProductId = productSwapMap.page[lineItem.productId]
  }

  if (!replacementProductId || !lineItem.isTemplate) {
    return replacementProductId
  }

  const productTemplateId = lineItem.productTemplateId!
  // If the current line item has product template, also need to check if the template has a variant that can fit the new replacement product ratio
  const matchingVariants = yield call(getProductTemplateVariantForProduct, {
    productId: replacementProductId,
    productTemplateId: productTemplateId,
  })

  return matchingVariants.length > 0 ? replacementProductId : null
}

// =================================================================
// Entry point for computing product swap map
// for store switching
// =================================================================
export function* computeProductSwapMapToTargetedStore(payload: {
  toStoreId: EntityId
}): SagaIterator<ProductSwapMap> {
  const { toStoreId } = payload
  const pageSession: PageSessionEntity = yield call(getCurrentPageSession)

  const productsInStore: PrintServiceProductEntity[] = yield call(
    getProductsForStore,
    { storeId: toStoreId }
  )

  const swapMap: ProductSwapMap = yield call(assembleSwapMap, {
    targetedFulfillment: "pickup",
    pageSession: pageSession,
    storeProducts: productsInStore,
  })

  return swapMap
}

// =================================================================
// Entry point for computing product swap map
// for fulfillment switching
// =================================================================
export function* computeProductSwapMapToTargetedFulfillment(payload: {
  toFulfillment: FulfillmentTypes
  pageId: EntityId
}): SagaIterator<ProductSwapMap> {
  const { toFulfillment, pageId } = payload

  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: pageId,
  })
  const cart: CartEntity = yield call(getCart, pageSession.cartId)

  let productsInStore: PrintServiceProductEntity[] = []
  if (
    toFulfillment === "pickup" &&
    pageSession.pageFlow === "store-first" &&
    pageSession.hasLoadedStores &&
    cart.storeId
  ) {
    // user previously had a store configured
    // retrieve products available in the store for later filtering
    productsInStore = yield call(getProductsForStore, { storeId: cart.storeId })
  }

  const swapMap: ProductSwapMap = yield call(assembleSwapMap, {
    targetedFulfillment: toFulfillment,
    pageSession: pageSession,
    storeProducts: productsInStore,
  })

  return swapMap
}

// =================================================================
// assemble the product swap map based on the current
// cart line items and a provided list of target products
// returns a product swap map for the page and each blocks
//
// map: {
//  page: {[fromProductId]: toProductId},
//  blocks: {
//    [blockId] : {[fromProductId]: toProductId},
//  }
// }
// =================================================================
function* assembleSwapMap(payload: {
  targetedFulfillment: FulfillmentTypes
  pageSession: PageSessionEntity
  storeProducts: PrintServiceProductEntity[]
}): SagaIterator<ProductSwapMap> {
  const { targetedFulfillment, pageSession, storeProducts } = payload

  // get page products
  let pageProducts: PrintServiceProductEntity[] = yield call(
    getPageProductsForFulfillment,
    { pageId: pageSession.id, fulfillment: targetedFulfillment }
  )

  // filter page products to only those which are also enabled on the store
  if (storeProducts.length > 0) {
    pageProducts = pageProducts.filter(product =>
      storeProducts.find(p => product.id === p.id)
    )
  }

  const allLineItems: LineItemEntity[] = yield call(getCartLineItems)
  const lineItems = allLineItems.filter(({ isReady }) => isReady)
  const blockIds: EntityId[] = uniq(
    lineItems
      .filter(lineItem => lineItem.isTemplate)
      .map(lineItem => lineItem.blockId!)
  )

  const productsInCart: PrintServiceProductEntity[] = yield call(
    getPrintServiceProducts,
    { ids: lineItems.map(lineItem => lineItem.productId) }
  )

  const swapMap: ProductSwapMap = {
    page: {},
    blocks: {},
  }

  // page level map
  const map: ProductSwap = {}
  productsInCart.forEach(product => {
    const found = getMostCompatibleProduct(
      product,
      getCompatibleProducts(product, pageProducts)
    )
    map[product.id] = found ? found.id : null
  })
  swapMap.page = map

  // per block map
  for (const blockId of blockIds) {
    const blockMap: ProductSwap = {}
    let blockProducts: PrintServiceProductEntity[] = yield call(
      getBlockProductsForFulfillment,
      {
        fulfillment: targetedFulfillment,
        blockId,
      }
    )

    // filter block product to match store product if required
    if (storeProducts.length > 0) {
      blockProducts = blockProducts.filter(product =>
        storeProducts.find(p => product.id === p.id)
      )
    }

    productsInCart.forEach(product => {
      const found = getMostCompatibleProduct(
        product,
        getCompatibleProducts(product, blockProducts)
      )
      blockMap[product.id] = found ? found.id : null
    })
    swapMap.blocks[blockId] = blockMap
  }

  return swapMap
}
