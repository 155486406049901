import { PrintServiceProductImageEntityHydrated } from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"

const {
  slice: printServiceProductImages,
  selectors: printServiceProductImagesSelector,
} = createEntitySlice<PrintServiceProductImageEntityHydrated>(
  "printServiceProductImages"
)

export { printServiceProductImages, printServiceProductImagesSelector }
