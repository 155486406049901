import { PrintServiceProductPriceEntity } from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"

const {
  slice: printServiceProductPrices,
  selectors: printServiceProductPricesSelector,
} = createEntitySlice<PrintServiceProductPriceEntity>(
  "printServiceProductPrices"
)

export { printServiceProductPrices, printServiceProductPricesSelector }
