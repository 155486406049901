import { EntityId, PrintServiceProductPriceEntity } from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { select } from "redux-saga/effects"
import { printServiceProductPricesSelector } from "../state/printServiceProductPrices"

export function* getPrintServiceProductPrice(
  printServiceProductPriceId: EntityId
): SagaIterator<PrintServiceProductPriceEntity> {
  const price: PrintServiceProductPriceEntity = yield select(state =>
    printServiceProductPricesSelector.selectById(
      state,
      printServiceProductPriceId
    )
  )

  return price
}
