import {
  BlockTemplateEntity,
  EntityId,
  PrinticularCategoryEntity,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateEntity,
} from "@jackfruit/common"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeEvery, take } from "redux-saga/effects"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { logger } from "~/services/Logger"
import { PrinticularApi } from "~/services/PrinticularApi"
import { actions, DownloadProductTemplates } from "../process"
import {
  blockTemplateActions,
  blockTemplatesSelectors,
} from "../state/blockTemplates"
import { categories as stateCategories } from "../state/categories"
import { lineItemsSelectors } from "../state/lineItems"
import {
  productTemplateCategories as stateProductTemplateCategories,
  productTemplates as stateProductTemplates,
  productTemplateSelectors,
  productTemplateTags,
  productTemplateTagSelectors,
} from "../state/productTemplates"
import { RootState } from "../store"
import { getApi } from "./api"

export default function* watchProcessDownloadProductTemplates() {
  yield takeEvery(
    actions.downloadProductTemplates.type,
    processDownloadProductTemplates
  )
}

function* waitForTemplateTags() {
  do {
    const isLoading: boolean = yield select(
      (state: RootState) => state.productTemplateTags.isLoading
    )
    if (!isLoading) {
      return
    }
    yield take(productTemplateTags.actions.setIsLoading.type)
  } while (true)
}

/**
 * For a given tags's slug list, fetch a list of productTemplates
 * on AP and attached it to a blockTemplate
 *
 * @param action
 */
function* processDownloadProductTemplates(
  action: PayloadAction<DownloadProductTemplates>
): SagaIterator<any> {
  const {
    blockTemplateId,
    preTags,
    tags,
    limit = 25,
    offset = 0,
    keyword,
  } = action.payload

  // get blockTemplate to check if it is restricted to active print services
  const blockTemplate: BlockTemplateEntity = yield select((state: RootState) =>
    blockTemplatesSelectors.selectById(state, blockTemplateId)
  )
  const { isRestrictedToActivePrintServices, printServices } = blockTemplate
  const pickupPrintServiceIds = printServices.pickup.map(
    printService => printService.id
  )
  const deliveryPrintServiceIds = printServices.delivery.map(
    printService => printService.id
  )

  const allPrintServiceIds = isRestrictedToActivePrintServices
    ? [...pickupPrintServiceIds, ...deliveryPrintServiceIds]
    : []

  const {
    isFetchingTemplates,
    setTotalRemoteProducts,
    setProductTemplateIds,
    setIsLoading,
    setError,
    setHasError,
  } = blockTemplateActions
  yield put(isFetchingTemplates(blockTemplateId, true))

  // sanitize tags as some can be configured but not available on the server
  // remove all configuration tags that are not available remotely to avoid empty results
  // but first wait for template tags to be fetched from server
  yield call(waitForTemplateTags)
  const availableTags: EntityId[] = yield select((state: RootState) =>
    productTemplateTagSelectors.selectIds(state)
  )
  const sanitizedTags = tags?.filter(tag => availableTags.includes(tag))

  try {
    const printicularApi: PrinticularApi = yield call(getApi)
    const {
      productTemplates,
      productTemplateCategories,
      categories,
      totalRemoteProducts,
    }: {
      productTemplates: PrinticularProductTemplateEntity[]
      productTemplateCategories: PrinticularProductTemplateCategoryEntity[]
      categories: PrinticularCategoryEntity[]
      totalRemoteProducts: number
    } = yield call([printicularApi, "getAvailableProductTemplates"], {
      preTags,
      tags: sanitizedTags,
      limit,
      offset,
      keyword,
      printServiceIds: allPrintServiceIds,
    })

    // Add productTemplates to debugJson for test automation
    window.debugJson.productTemplates = productTemplates
    window.debugJson.totalRemoteProducts = totalRemoteProducts

    yield put(setTotalRemoteProducts(blockTemplateId, totalRemoteProducts))

    // @TODO Temp - FIX for Autopilot issue : sortOrder 0 result null
    const editedProductTemplateCategories = productTemplateCategories.map(
      productTemplateCategory => {
        const { sortOrder } = productTemplateCategory
        if (sortOrder === null) {
          return {
            ...productTemplateCategory,
            sortOrder: 0,
          }
        }
        return productTemplateCategory
      }
    )

    // filter templates with no valid variants or no thumbnail
    const templatesWithVariants = productTemplates.filter(template => {
      template.variants = template.variants || []
      template.variants = template.variants.filter(
        variant => variant.status === "active"
      )
      const thumbnail = template?.thumbnail
      return (
        template.variants.length > 0 && thumbnail?.width && thumbnail?.height
      )
    })

    // isRestrictedToActivePrintServices is only used for folded cards
    // we can use this flag to bypass the call to get folded card templates
    if (isRestrictedToActivePrintServices) {
      const {
        productTemplates: foldedCardPageProductTemplates,
      }: {
        productTemplates: PrinticularProductTemplateEntity[]
      } = yield call([printicularApi, "getAvailableProductTemplates"], {
        printServiceIds: allPrintServiceIds,
        templateTypes: ["card-inside-left", "card-inside-right", "card-back"],
      })

      if (foldedCardPageProductTemplates.length > 0) {
        window.debugJson.foldedCards = foldedCardPageProductTemplates

        const cardInsideLeftPageTemplate = foldedCardPageProductTemplates.find(
          template => template.templateType === "card-inside-left"
        )

        const cardInsideRightPageTemplate = foldedCardPageProductTemplates.find(
          template => template.templateType === "card-inside-right"
        )

        const cardBackPageTemplate = foldedCardPageProductTemplates.find(
          template => template.templateType === "card-back"
        )

        if (
          cardInsideLeftPageTemplate &&
          cardInsideRightPageTemplate &&
          cardBackPageTemplate
        ) {
          const cardInsideLeftPage =
            cardInsideLeftPageTemplate.variants[0].pages[0]
          const cardInsideRightPage =
            cardInsideRightPageTemplate.variants[0].pages[0]
          const cardBackPage = cardBackPageTemplate.variants[0].pages[0]

          // Attach extra pages to folded card templates
          // assumption: each template that belong to the card category
          templatesWithVariants.forEach(
            (template, indexTemplate, originalTemplate) => {
              const isFoldedCard = template.templateGroups.find(
                group => group.type === "folded-cards"
              )

              if (isFoldedCard) {
                template.variants.forEach((_variant, indexVariant) => {
                  originalTemplate[indexTemplate].variants[
                    indexVariant
                  ].pages.push(
                    ...[cardInsideLeftPage, cardInsideRightPage, cardBackPage]
                  )
                })
              }
            }
          )
        } else {
          console.warn("missing template page for folded card", {
            cardInsideLeftPageTemplate,
            cardInsideRightPageTemplate,
            cardBackPageTemplate,
          })
        }
      }
    }

    // sanitize svg from all variants as some variants may contains
    // <?xml version="1.0"?> declaration wich prevent the svg from being inlined
    templatesWithVariants.forEach((template, i, templates) => {
      template.variants.forEach((variant, j) => {
        variant.pages.forEach((page, k) => {
          const { svg, imageRegions, textRegions } = page
          if (svg) {
            templates[i].variants[j].pages[k].svg = svg
              .replace(/<\?xml.+\?>|<!DOCTYPE.+]>/g, "")
              .trim()
          } else {
            console.error("Please check the config of template:", templates[i])
          }

          if (!imageRegions) {
            // fix image regions
            // api does not returns an empty array anymore
            templates[i].variants[j].pages[k].imageRegions = []
          }

          if (!textRegions) {
            // fix text regions
            // api does not returns an empty array anymore
            templates[i].variants[j].pages[k].textRegions = []
          }
        })
      })
    })

    // Preserve line items product templates on pagination change
    const lineItems: LineItemEntity[] = yield select((state: RootState) =>
      lineItemsSelectors.selectAll(state)
    )

    const lineItemsProductTemplateIds: EntityId[] = []
    lineItems.forEach(lineItem => {
      if (lineItem.isTemplate) {
        lineItemsProductTemplateIds.push(lineItem.productTemplateId!.toString())
      }
    })

    const productTemplatesToKeep: PrinticularProductTemplateEntity[] =
      yield select((state: RootState) =>
        productTemplateSelectors.selectByIds(state, lineItemsProductTemplateIds)
      )

    const allTemplateWithVariantIds = templatesWithVariants.map(pT => pT.id)

    productTemplatesToKeep.forEach(productTemplate => {
      const foundInNewPage = templatesWithVariants.find(
        current => current.id === productTemplate.id
      )
      if (!foundInNewPage) {
        templatesWithVariants.push(productTemplate)
      }
    })

    yield put(setProductTemplateIds(blockTemplateId, []))

    yield put(
      stateProductTemplateCategories.actions.upsertMany(
        editedProductTemplateCategories // @TODO To edit after fixing the sortOrder issue on Autopilot
      )
    )
    yield put(stateCategories.actions.upsertMany(categories))
    yield put(stateProductTemplates.actions.upsertMany(templatesWithVariants))

    yield put(setProductTemplateIds(blockTemplateId, allTemplateWithVariantIds))

    yield put(
      productTemplateTags.actions.upsertMany(
        sanitizedTags?.map(id => {
          return {
            id,
            isFetchingTemplates: false,
          }
        })
      )
    )
  } catch (error) {
    logger.error(error)
    yield put(setError(blockTemplateId, error.message))
    yield put(setHasError(blockTemplateId, true))
  } finally {
    yield put(setIsLoading(blockTemplateId, false))
  }
}
