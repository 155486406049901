import { EntityId } from "@jackfruit/common"
import { select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { RootState } from "../store"

export function* getPrintServiceProductImages(
  productId: EntityId
): SagaIterator {
  const product = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, productId)
  )

  const productImages = yield select((state: RootState) =>
    printServiceProductImagesSelector.selectByIds(
      state,
      product?.printServiceProductImages || []
    )
  )

  return productImages.filter((value: any) => value !== undefined)
}
