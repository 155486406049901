import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { textRegions, textRegionsSelectors } from "~/redux/state/textRegions"
import { RootState } from "~/redux/store"

export const useTextRegion = (id: EntityId) => {
  const textRegion = useSelector((state: RootState) =>
    textRegionsSelectors.selectById(state, id)
  )

  return {
    textRegion,
  }
}

export const useTextRegionActions = () => {
  const dispatch = useDispatch()

  return {
    updateTextRegion: useCallback(
      (id: EntityId, changes: Partial<TextRegionEntity>) => {
        dispatch(
          textRegions.actions.updateOne({
            id,
            changes,
          })
        )
      },
      [dispatch]
    ),
  }
}
