import { Box } from "@chakra-ui/layout"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import HeaderBlock from "../../../components/blocks/HeaderBlock"

interface Props {
  config: AppEntityHydrated
}

const Header: React.FC<Props> = ({ config }) => {
  const block = config.pages[0].blocks.find(
    (block: any) => block.type === "header"
  )?.header!

  return (
    <Box className="block-header">
      <HeaderBlock config={block} pages={config.pages} appConfig={config} />
    </Box>
  )
}

export default Header
