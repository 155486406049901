import { Box } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuCellHtml: React.FC<Props> = ({ cell }) => {
  return (
    <Box
      mt={2}
      width="full"
      height="full"
      dangerouslySetInnerHTML={{ __html: cell.html }}
    />
  )
}

export default MegaMenuCellHtml
