import { VStack } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"
import MegaMenuCellLink from "./MegaMenuCellLink"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuCellLinks: React.FC<Props> = ({ cell }) => {
  const { links } = cell

  return (
    <VStack alignItems="flex-start" spacing={2}>
      {links.map(link => {
        return <MegaMenuCellLink key={link.id} link={link} />
      })}
    </VStack>
  )
}

export default MegaMenuCellLinks
