import {
  FulfillmentTypes,
  PrintServiceProductEntityHydrated,
} from "@jackfruit/common"
import { Dictionary, createSelector } from "@reduxjs/toolkit"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import { createEntitySlice } from "../createEntitySlice"
import { RootState } from "../store"
import { blocksSelector } from "./blocks"
import { storesSelectors } from "./stores"

const { slice, selectors } =
  createEntitySlice<PrintServiceProductEntityHydrated>("printServiceProducts")

const printServiceProducts = slice
const printServiceProductsSelector = selectors

const getCurrentPage = (state: RootState) =>
  state.pages.entities[state.application.currentPageId]!

const getCurrentPageSession = (state: RootState) =>
  state.pageSessions.entities[state.application.currentPageId]!

const getCurrentFulfilment = (state: RootState) =>
  state.carts.entities[state.application.currentPageId]!.fulfillment

const getCurrentStore = (state: RootState) => {
  const storeId = state.carts.entities[state.application.currentPageId]!.storeId
  return storesSelectors.selectById(state, storeId)
}

const getCurrentPrintServiceId = (state: RootState) =>
  state.carts.entities[state.application.currentPageId]!.printServiceId

const getAllProducts = (state: RootState) => {
  return printServiceProductsSelector.selectEntities(state)
}

const getStoreProducts = createSelector(
  getCurrentPageSession,
  getCurrentFulfilment,
  getCurrentStore,
  getAllProducts,
  (
    pageSession: PageSessionEntity,
    fulfillment: FulfillmentTypes,
    store: StoreEntity | undefined,
    products: Dictionary<PrintServiceProductEntityHydrated>
  ) => {
    if (
      store &&
      fulfillment === "pickup" &&
      pageSession.pageFlow === "store-first"
    ) {
      // filter out only store products
      const storeProducts = Object.values(products).filter(product =>
        store.products.includes(product.productCode)
      )
      return storeProducts.reduce((acc, product) => {
        acc[product.id] = product
        return acc
      }, {})
    } else {
      return products
    }
  }
)

const getOrderedProducts = (
  products: Dictionary<PrintServiceProductEntityHydrated>,
  productConfigurations: any
) => {
  if (productConfigurations === undefined) {
    return []
  }
  const orderedProducts = Object.keys(productConfigurations)
    .filter(productId => !!productConfigurations[productId])
    .map(productId => products[productId]!)
    .filter(product => product !== undefined)
    .sort((a, b) => {
      const p1 = productConfigurations[+a.id]
      const p2 = productConfigurations[+b.id]

      return p1.position > p2.position ? -1 : 1
    })
    .map((product, index) => {
      return {
        ...product,
        position: index,
      }
    })
  return orderedProducts
}

const availableProductsForPageSelector = createSelector(
  getCurrentPage,
  getCurrentFulfilment,
  getCurrentPrintServiceId,
  getStoreProducts,
  (page, fulfillment, printServiceId, products) => {
    const productConfigurations: any[] =
      page.products[fulfillment][+printServiceId]
    return getOrderedProducts(products, productConfigurations)
  }
)

const availableProductsForBlockSelector = createSelector(
  blocksSelector.selectById,
  getCurrentFulfilment,
  getCurrentPrintServiceId,
  getStoreProducts,
  (block: any, fulfillment, printServiceId, products) => {
    const productConfigurations: any[] =
      block[block.type].products[fulfillment][+printServiceId]
    return getOrderedProducts(products, productConfigurations)
  }
)

export {
  printServiceProducts,
  printServiceProductsSelector,
  availableProductsForPageSelector,
  availableProductsForBlockSelector,
}
