import { PrintServiceProductCategoryEntity } from "@jackfruit/common"
import { createEntitySlice } from "../createEntitySlice"

const {
  slice: printServiceProductCategories,
  selectors: printServiceProductCategoriesSelector,
} = createEntitySlice<PrintServiceProductCategoryEntity>(
  "printServiceProductCategories"
)

export { printServiceProductCategories, printServiceProductCategoriesSelector }
