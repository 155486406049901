import { AppEntityHydrated, BlockFooterEntityHydrated } from "@jackfruit/common"
import React from "react"
import FooterBlockMegamenuContent from "./block-footer-megamenu/FooterBlockMegamenuContent"
import FooterBlockContent from "./block-footer/FooterBlockContent"

interface Props {
  appConfig: AppEntityHydrated
  config: BlockFooterEntityHydrated
}

const FooterBlock: React.FC<Props> = ({ appConfig, config }) => {
  const { footerType, navigationMegamenuId } = config

  const shouldShowAsMegamenu = Boolean(
    footerType === "megaMenu" && navigationMegamenuId
  )

  if (shouldShowAsMegamenu) {
    return <FooterBlockMegamenuContent appConfig={appConfig} config={config} />
  } else {
    return <FooterBlockContent appConfig={appConfig} config={config} />
  }
}

export default FooterBlock
