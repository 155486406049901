import { AvailableLanguages } from "@jackfruit/common"
import { isMaintenance } from "./ApiExceptionHelper"
import { PrinticularApi } from "./PrinticularApi"
import { alertUserBeforeLeaving } from "./Utils"

const ignoredMethods = [
  "ping",
  "get",
  "post",
  "setDeviceToken",
  "getDeviceToken",
  "setAccessToken",
  "getAccessToken",
]

type ApiMethods = keyof PrinticularApi

const maintenanceModeHandler: ProxyHandler<PrinticularApi> = {
  get(target, prop: ApiMethods) {
    const apiProperty = target[prop]
    if (apiProperty instanceof Function && !ignoredMethods.includes(prop)) {
      return async function (...args: any[]) {
        try {
          const result = await apiProperty.apply(target, args)
          return result
        } catch (error: any) {
          if (isMaintenance(error)) {
            window.removeEventListener("beforeunload", alertUserBeforeLeaving)
            // hard redirect to force store flush
            window.location.href = "maintenance"
          }
          throw error
        }
      }
    }
    return apiProperty
  },
}

export const createPrinticularApi = (
  remoteApiUrl: string,
  authToken: string,
  deviceToken: string,
  language: AvailableLanguages
) => {
  const api = new PrinticularApi(remoteApiUrl, authToken, deviceToken, language)

  return new Proxy(api, maintenanceModeHandler)
}
