import { UseToastOptions } from "@chakra-ui/react"
import { NavigateFn } from "@reach/router"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthResume } from "~/interfaces/entities/AuthResume"
import { AuthUser, AuthUserInfos } from "~/interfaces/entities/AuthUser"

export interface AuthState extends AuthUser {
  isAuthenticated: boolean
  isAuthenticating: boolean
  isLoading: boolean
  hasError: boolean
  error: string
}

const initialState: AuthState = {
  // entity
  id: 0,
  name: "",
  phoneNumber: "",
  emailAddress: "",
  loginToken: "",
  // flags
  isAuthenticated: false,
  isAuthenticating: true,
  isLoading: false,
  hasError: false,
  error: "",
}

export interface LoginPayload {
  login: string
  password: string
  toast: (options?: UseToastOptions) => void
}

export interface LoginSuccessPayload {
  authUser: AuthUser
  navigate?: NavigateFn
  toast?: (options?: UseToastOptions) => void
}

export interface LogoutPayload {
  toast?: (options?: UseToastOptions) => void
}

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state: AuthState, _action: PayloadAction<LoginPayload>) {
      state.isAuthenticating = true
    },
    loginSilently(_state: AuthState, _action: PayloadAction) {},
    logout(state: AuthState, _action: PayloadAction<any>) {
      state.id = 0
      state.name = ""
      state.phoneNumber = ""
      state.emailAddress = ""
      state.loginToken = ""
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.isLoading = false
      state.hasError = false
      state.error = ""
    },
    loginSuccess(state: AuthState, action: PayloadAction<LoginSuccessPayload>) {
      const { authUser } = action.payload
      const { id, name, emailAddress, loginToken, phoneNumber } = authUser

      state.id = id
      state.name = name
      state.phoneNumber = phoneNumber
      state.emailAddress = emailAddress
      state.loginToken = loginToken
      state.isLoading = false
      state.isAuthenticated = true
      state.isAuthenticating = false
      state.error = ""
      state.hasError = false
    },
    loginResume(_state: AuthState, _action: PayloadAction) {},
    loginResumeSuccess(state: AuthState, action: PayloadAction<AuthResume>) {
      const { token } = action.payload
      state.loginToken = token
      state.isAuthenticating = false
    },
    loginFailed(state: AuthState, action: PayloadAction<string>) {
      const message = action.payload

      state.loginToken = ""
      state.isLoading = false
      state.isAuthenticated = false
      state.isAuthenticating = false
      state.error = message
      state.hasError = true
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setIsAuthenticating(state, action) {
      state.isAuthenticating = action.payload
    },
    setUserInfos(state: AuthState, action: PayloadAction<AuthUserInfos>) {
      const { name, phoneNumber } = action.payload
      state.name = name
      state.phoneNumber = phoneNumber
    },
  },
})
