import { EntityId } from "@jackfruit/common"

export interface S3Result {
  bucket: string
  key: string
  location: string
}

export interface PublicBucketConfig {
  bucket: string
  deviceId: string
}

export class S3Service {
  public upload(
    id: EntityId,
    hash: string,
    hash64: string,
    file: File,
    config: PublicBucketConfig,
    onSuccess?: (id: EntityId, data: any) => void,
    onError?: (id: EntityId, error: Error) => void
  ) {
    const s3key = `${config.deviceId}/${hash}`

    const formData = new FormData()
    formData.append("key", s3key)
    formData.append("Content-Type", file.type)
    formData.append("Content-MD5", hash64)
    formData.append("filename", hash)
    formData.append("name", "")
    formData.append("file", file, hash)

    const request = new XMLHttpRequest()

    request.open(
      "POST",
      `https://${config.bucket}.s3-accelerate.amazonaws.com:443/`
    )

    request.addEventListener("error", () => {
      onError?.(id, new Error("Upload failed, please refresh your browser"))
    })

    request.addEventListener("load", () => {
      if ([200, 201, 204].includes(request.status)) {
        onSuccess?.(id, {
          bucket: config.bucket,
          hash,
          location: `https://${config.bucket}.s3.amazonaws.com:443/${s3key}`,
        })
      } else {
        onError?.(id, new Error("Upload failed, please refresh your browser"))
      }
    })

    request.send(formData)
  }
}
