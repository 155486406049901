import { EntityId } from "@jackfruit/common"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"
import {
  imageTransformationMinZoomStateFamily,
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
  textRegionAlignFamily,
  textRegionColorFamily,
  textRegionFontFamily,
  textRegionSizeFamily,
  textRegionTextFamily,
} from "~/components/editors/common/atoms"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { actions as process, ApplyEditorChangesPayload } from "../process"
import {
  imageTransformations,
  imageTransformationsSelectors,
} from "../state/imageTransformations"
import { lineItems } from "../state/lineItems"
import { textRegions } from "../state/textRegions"
import { RootState } from "../store"
import { getImageRegion } from "./imageRegion"
import { getImage } from "./images"
import { getLineItem } from "./lineItems"
import { getProductPage } from "./productPages"
import { getUpload } from "./uploads"

export default function* watchProcessApplyEditorChanges() {
  yield takeLatest(process.applyEditorChanges.type, processApplyEditorChanges)
}

function* processApplyEditorChanges(
  action: PayloadAction<ApplyEditorChangesPayload>
): SagaIterator<any> {
  const { lineItemId, snapshot } = action.payload

  // retrieve all image transformations
  const allImageTrasformIds: EntityId[] = []
  const allTextRegionIds: EntityId[] = []
  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)

  for (let i = 0; i < lineItem.productPageIds.length; i++) {
    const productPageId = lineItem.productPageIds[i]
    const productPage: ProductPageEntity = yield call(
      getProductPage,
      productPageId
    )

    for (let j = 0; j < productPage.imageRegionIds.length; j++) {
      const imageRegionId = productPage.imageRegionIds[j]
      const imageRegion: ImageRegionEntity = yield call(
        getImageRegion,
        imageRegionId
      )
      if (imageRegion.uploadId) {
        const upload: UploadEntity = yield call(getUpload, imageRegion.uploadId)
        const image: ImageEntity = yield call(getImage, upload.imageId)
        allImageTrasformIds.push(image.imageTransformId)
      }
    }

    for (let j = 0; j < productPage.textRegionIds.length; j++) {
      allTextRegionIds.push(productPage.textRegionIds[j])
    }
  }

  for (let i = 0; i < allTextRegionIds.length; i++) {
    const textRegionId = allTextRegionIds[i]

    const align = yield call(
      snapshot.getPromise,
      textRegionAlignFamily(textRegionId)
    )

    const color = yield call(
      snapshot.getPromise,
      textRegionColorFamily(textRegionId)
    )

    const font = yield call(
      snapshot.getPromise,
      textRegionFontFamily(textRegionId)
    )

    const text = yield call(
      snapshot.getPromise,
      textRegionTextFamily(textRegionId)
    )

    const size = yield call(
      snapshot.getPromise,
      textRegionSizeFamily(textRegionId)
    )

    yield put(
      textRegions.actions.updateOne({
        id: textRegionId,
        changes: {
          align,
          color,
          font,
          text,
          size,
        },
      })
    )
  }

  for (let i = 0; i < allImageTrasformIds.length; i++) {
    const imageTransformId = allImageTrasformIds[i]
    const rotation = yield call(
      snapshot.getPromise,
      imageTransformationRotationStateFamily(imageTransformId)
    )
    const translation = yield call(
      snapshot.getPromise,
      imageTransformationTranslationStateFamily(imageTransformId)
    )
    const minZoom = yield call(
      snapshot.getPromise,
      imageTransformationMinZoomStateFamily(imageTransformId)
    )
    const zoom = yield call(
      snapshot.getPromise,
      imageTransformationZoomStateFamily(imageTransformId)
    )
    const current: ImageTransformationEntity = yield select(
      (state: RootState) =>
        imageTransformationsSelectors.selectById(state, imageTransformId)
    )

    const dirty =
      current.minZoom !== minZoom ||
      current.rotation !== rotation ||
      current.translation.x !== translation.x ||
      current.translation.y !== translation.y ||
      current.zoom !== zoom

    yield put(
      imageTransformations.actions.updateOne({
        id: imageTransformId,
        changes: {
          rotation,
          translation,
          zoom,
          minZoom,
          dirty,
        },
      })
    )
  }

  // confirm line item
  yield put(
    lineItems.actions.updateOne({
      id: lineItemId,
      changes: {
        isConfirmed: true,
      },
    })
  )
}
