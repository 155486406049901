import { SettingsEntityHydrated } from "@jackfruit/common"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface SettingsState {
  data: SettingsEntityHydrated
}

const initialState: SettingsState = {
  data: {
    isSitemapEnabled: true,
    isSitemapHideFooter: false,
    sitemapLinkLabel: "All products",
    sitemapNavigationId: null,
  },
} as SettingsState

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings(state, action: PayloadAction<SettingsEntityHydrated>) {
      state.data = action.payload
    },
    updateDeviceToken(state, action: PayloadAction<string>) {
      state.data.deviceToken = action.payload
    },
  },
})
