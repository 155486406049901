import { EntityId } from "@jackfruit/common"
import { select, call, put, getContext } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { pageSessions, pageSessionsSelector } from "../state/pageSessions"
import { RootState } from "../store"

export function* getCurrentPageSession(): SagaIterator<PageSessionEntity> {
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, currentPageId)
  )

  return pageSession
}

export function* getPageSession(payload: {
  pageSessionId: EntityId
}): SagaIterator<PageSessionEntity> {
  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, payload.pageSessionId)
  )

  return pageSession
}

export function* attachUploadToPageSession(uploadId: EntityId): SagaIterator {
  const pageSession = yield call(getCurrentPageSession)
  yield put(
    pageSessions.actions.updateOne({
      id: pageSession.id,
      changes: {
        uploadIds: [...pageSession.uploadIds, uploadId],
      },
    })
  )
}
