import { BlockTemplateEntityHydrated, EntityId } from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { createEntitySlice } from "../createEntitySlice"

const {
  slice: blockTemplates,
  selectors: blockTemplatesSelectors,
} = createEntitySlice<BlockTemplateEntityHydrated>("blockTemplates")

const blockTemplatesFactory = (
  data: Partial<BlockTemplateEntityHydrated>
): any => {
  // @TODO Fix typing issues
  return {
    id: nanoid(),
    printServiceProductIds: [],
    productTemplateIds: [],
    totalRemoteProducts: 0,
    isLoading: false,
    hasError: false,
    error: "",
    ...data,
  }
}

const blockTemplateActions = {
  setTotalRemoteProducts: (id: EntityId, totalRemoteProducts: number) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        totalRemoteProducts,
      },
    })
  },
  setProductTemplateIds: (id: EntityId, productTemplateIds: EntityId[]) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        productTemplateIds,
      },
    })
  },
  isFetchingTemplates: (id: EntityId, isFecthing: boolean) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        isLoading: isFecthing,
        hasError: false,
        error: "",
      },
    })
  },
  setIsLoading: (id: EntityId, isLoading: boolean) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        isLoading,
      },
    })
  },
  setHasError: (id: EntityId, hasError: boolean) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        hasError,
      },
    })
  },
  setError: (id: EntityId, error: string) => {
    return blockTemplates.actions.updateOne({
      id,
      changes: {
        error,
      },
    })
  },
}

export {
  blockTemplatesFactory,
  blockTemplates,
  blockTemplatesSelectors,
  blockTemplateActions,
}
