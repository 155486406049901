import { useSelector } from "react-redux"
import { templateTextFontsSelector } from "~/redux/state/template-text-fonts"

export const useTemplateTextFonts = () => {
  const fonts = useSelector(templateTextFontsSelector.selectAll)

  return {
    fonts,
  }
}
