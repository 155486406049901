import { EntityId } from "@jackfruit/common"
import { select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { StoreEntity } from "~/interfaces/entities/Store"
import { storesSelectors } from "../state/stores"
import { RootState } from "../store"

export function* getStore(payload: {
  storeId: EntityId
}): SagaIterator<StoreEntity> {
  const { storeId } = payload
  const store: StoreEntity = yield select((state: RootState) =>
    storesSelectors.selectById(state, storeId)
  )

  return store
}
