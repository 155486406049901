import { EntityId } from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { createEntitySlice } from "../createEntitySlice"

const { slice: pageSessions, selectors: pageSessionsSelector } =
  createEntitySlice<PageSessionEntity>("pageSessions")

const pageSessionsFactory = (
  data: Partial<PageSessionEntity>
): PageSessionEntity => {
  return {
    id: nanoid(),
    cartId: 0,
    orderSummaryId: 0,
    uploadIds: [],
    storeIds: [],
    productIds: [],
    pageFlow: "product-first",
    isReady: false,
    hasLoadedStores: false,
    ...data,
  }
}

const pageSessionsActions = {
  setIsReady: (id: EntityId, isReady: boolean) => {
    return pageSessions.actions.updateOne({
      id,
      changes: {
        isReady,
      },
    })
  },
  setStoreIds: (id: EntityId, storeIds: EntityId[]) => {
    return pageSessions.actions.updateOne({
      id,
      changes: {
        storeIds,
      },
    })
  },
  setProductIds: (id: EntityId, productIds: EntityId[]) => {
    return pageSessions.actions.updateOne({
      id,
      changes: {
        productIds,
      },
    })
  },
}

export {
  pageSessions,
  pageSessionsActions,
  pageSessionsSelector,
  pageSessionsFactory,
}
