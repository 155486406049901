import { Box, Heading, SimpleGrid } from "@chakra-ui/react"
import { AppEntityHydrated, BlockFooterEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import AppStoreButtons from "~/components/common/AppStoreButtons"
import FooterBlockMegamenuColumn from "./FooterBlockMegamenuColumn"

interface Props {
  config: BlockFooterEntityHydrated
  appConfig: AppEntityHydrated
}

const FooterBlockMegamenu: React.FC<Props> = ({ config, appConfig }) => {
  const { t } = useTranslation()
  const { iosStoreUrl, androidStoreUrl, language } = appConfig
  const { showAppButtons, megamenu } = config
  const { columns } = megamenu

  // replace with toSorted when it's available
  const sortedColumns = [...columns].sort((a, b) => a.order - b.order)

  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={4}>
      {sortedColumns.map(column => {
        return <FooterBlockMegamenuColumn column={column} key={column.id} />
      })}
      {Boolean(showAppButtons) && (
        <Box>
          <Heading as="h4" size="sm" mb={2}>
            {t("components.blocks.FooterBlock.appButtons.title")}
          </Heading>
          <AppStoreButtons
            direction="column"
            justify="flex-start"
            appStoreUrl={iosStoreUrl}
            googlePlayUrl={androidStoreUrl}
            language={language}
            isCheckDevice={false}
          />
        </Box>
      )}
    </SimpleGrid>
  )
}

export default FooterBlockMegamenu
