import {
  Flex,
  Icon,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useTheme,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaUserCircle } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri"
import { useAuth } from "~/hooks/useAuth"
import { useSettings } from "~/hooks/useSettings"

const MyAccountMenu = ({ color = "inherit" }: { color?: string }) => {
  const { t } = useTranslation()
  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  const { logout } = useAuth()

  if (!isUserAccountEnabled) {
    return null
  }

  return (
    <Menu placement="bottom-end" closeOnSelect={false} isLazy={true}>
      {({ isOpen }) => (
        <>
          <MenuButton
            className="p-header-my-account"
            name="p-header-my-account"
            display="flex"
            alignItems="stretch"
            color={color}
            _active={{ textDecoration: "underline" }}
            _hover={{ textDecoration: "underline" }}
          >
            <Flex
              display="flex"
              alignItems="center"
              fontSize="xs"
              height="100%"
            >
              <LoggedInMessage />
              <Icon
                as={isOpen ? RiArrowUpSFill : RiArrowDownSFill}
                ml={1}
                boxSize={4}
              />
            </Flex>
          </MenuButton>

          <MenuList zIndex={999} boxShadow="md">
            <MenuGroup>
              <MenuItemLink
                id="my-account-link"
                title={t("components.header.MyAccount.MyAccountMenu.MyAccount")}
                link="/account"
              />
              <MenuItemLink
                id="order-history-link"
                title={t(
                  "components.header.MyAccount.MyAccountMenu.OrdersHistory"
                )}
                link="/account/orders"
              />
              <MenuItemLink
                id="change-password-link"
                title={t(
                  "components.header.MyAccount.MyAccountMenu.ChangePassword"
                )}
                link="/account/change-password"
              />
            </MenuGroup>
            <MenuDivider />
            <MenuGroup>
              <MenuItem id="sign-out-link" onClick={logout} p={0}>
                <ChakraLink
                  px={3}
                  py={2}
                  width="100%"
                  alignItems="center"
                  color="gray.600"
                >
                  {t("components.header.MyAccount.MyAccountMenu.SignOut")}
                  <Icon as={FiLogOut} ml={3} boxSize={5} />
                </ChakraLink>
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default MyAccountMenu

const MenuItemLink = ({
  title,
  link,
  id,
}: {
  title: string
  link: string
  id?: string
}) => {
  const theme = useTheme()
  const titleToKebabCase = kebabCase(title)

  return (
    <MenuItem p={0} _hover={{ textDecoration: "underline" }}>
      <Link
        id={id}
        to={link}
        style={{
          width: "100%",
          paddingBottom: ".6rem",
          paddingTop: ".6rem",
          paddingLeft: ".8rem",
          paddingRight: ".8rem",
        }}
        activeStyle={{
          color: theme.colors.primary["500"],
        }}
      >
        {title}
      </Link>
    </MenuItem>
  )
}

export const LoggedInMessage = () => {
  const { t } = useTranslation()
  const { state: authState } = useAuth()

  return (
    <Flex
      alignItems="center"
      width={["90%", "90%", "90%", "100%"]}
      color="inherit"
    >
      <Icon as={FaUserCircle} mr={2} boxSize={5} />
      <Text
        isTruncated
        maxW={["100%", "100%", "100%", 40]}
        fontWeight={["bold", "bold", "bold", "normal"]}
        color="inherit"
      >
        {authState?.name
          ? t(
              "components.header.MyAccount.MyAccountMenu.LoggedInMessage.WithName",
              { name: authState?.name }
            )
          : t(
              "components.header.MyAccount.MyAccountMenu.LoggedInMessage.WithoutName"
            )}
      </Text>
    </Flex>
  )
}
