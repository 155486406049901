import { Flex, Heading, ListItem, UnorderedList } from "@chakra-ui/react"
import { NavigationMegaMenuCellEntityHydrated } from "@jackfruit/common"
import React from "react"
import FooterBlockMegamenuLink from "./FooterBlockMegamenuLink"

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const FooterBlockMegamenuNavigation: React.FC<Props> = ({ cell }) => {
  const { title, links } = cell

  // replace with toSorted when it's widely available
  const sortedLinks = [...links].sort((a, b) => a.order - b.order)

  return (
    <Flex flexDirection="column">
      <Heading as="h4" size="sm" mb={4}>
        {title}
      </Heading>
      <UnorderedList styleType="''" m={0} spacing={2}>
        {sortedLinks.map(link => {
          const { id } = link
          return (
            <ListItem key={id}>
              <FooterBlockMegamenuLink link={link} />
            </ListItem>
          )
        })}
      </UnorderedList>
    </Flex>
  )
}

export default FooterBlockMegamenuNavigation
