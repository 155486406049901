import { nanoid } from "@reduxjs/toolkit"
import matchAll from "string.prototype.matchall"
import replaceAll from "string.prototype.replaceall"

export const sanitizeTemplate = (svg: string) => {
  const allIdMatches = [...matchAll(svg, /\(#(?<id>.*?)\)/g)]
  const currentUrl = window.location.href

  let cleanedIdsSvg = svg
  allIdMatches.forEach(match => {
    const id = match[1]
    const newId = `${id}__${nanoid()}`
    cleanedIdsSvg = replaceAll(cleanedIdsSvg, `id="${id}"`, `id="${newId}"`)
    cleanedIdsSvg = replaceAll(cleanedIdsSvg, `id='${id}'`, `id="${newId}"`)
    cleanedIdsSvg = replaceAll(
      cleanedIdsSvg,
      `xlink:href="#${id}"`,
      `xlink:href="${currentUrl}#${newId}"`
    )
    cleanedIdsSvg = replaceAll(
      cleanedIdsSvg,
      `xlink:href='#${id}'`,
      `xlink:href="${currentUrl}#${newId}"`
    )
    cleanedIdsSvg = replaceAll(
      cleanedIdsSvg,
      `url(#${id})`,
      `url(${currentUrl}#${newId})`
    )
  })

  const templateId = `template__${nanoid()}`
  cleanedIdsSvg = replaceAll(
    cleanedIdsSvg,
    'id="template"',
    `id="${templateId}"`
  )
  cleanedIdsSvg = replaceAll(
    cleanedIdsSvg,
    "id='template'",
    `id="${templateId}"`
  )

  return cleanedIdsSvg
}
