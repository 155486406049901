import { Flex, Button, Center, Grid, Icon, Image, Text } from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiX } from "react-icons/fi"

interface Props {
  appConfig: AppEntityHydrated
  onInstall: () => void
  onCloseBanner: () => void
  slideIn: boolean
  transitionTime: number
}

const AppBannerTop: React.FC<Props> = ({
  appConfig,
  onInstall,
  onCloseBanner,
  slideIn,
  transitionTime,
}) => {
  const { displayName, faviconImage } = appConfig

  const { t } = useTranslation()

  return (
    <Center
      p={0}
      bg="gray.700"
      id="install-mobile-app-banner"
      overflow="hidden"
      flexWrap="wrap"
      minHeight="104px"
    >
      <Grid
        templateColumns="1fr repeat(3, auto) 1fr"
        justifyContent="space-between"
        marginTop={slideIn ? 0 : "-88px"}
        transition={`${transitionTime}ms all`}
        width="full"
        p={4}
      >
        <Button
          id="p-mobile-banner-dismiss-button" // GTM: click on mobile banner dismiss button
          bg="none"
          color="white"
          onClick={onCloseBanner}
          mr="auto"
          my="auto"
          ml={0}
          p={0}
          minW={{ base: 6, sm: 8, md: 10 }}
        >
          <Icon as={FiX} boxSize={6} color="gray.200" />
        </Button>
        <Flex id="row" align="center" mx={{ base: 3, sm: 5 }}>
          <Image
            className="p-mobile-banner-app-icon" // GTM: click on mobile banner app icon
            src={faviconImage.path}
            htmlWidth={56}
            htmlHeight={56}
            width={{ base: "38px", sm: "56px" }}
            height={{ base: "38px", sm: "56px" }}
            bg="white"
            borderRadius="base"
            onClick={onInstall}
            ignoreFallback={true}
            mr={{ base: 3, sm: 5 }}
            my="auto"
            alt={`${displayName} Logo`}
            title={`${displayName} Logo`}
          />
          <Text
            color="white"
            fontSize={14}
            my="auto"
            lineHeight={{ base: 1.25, sm: 1.35, md: 1.5 }}
          >
            {t("components.AppBanner.Top.BannerTitle")}
          </Text>
        </Flex>
        <Button
          bg="primary.500"
          color="white"
          onClick={onInstall}
          className="btn btn-primary" // GTM: click on mobile banner store button
          my="auto"
          mr={{ base: 0, sm: 5 }}
        >
          {t("components.AppBanner.Top.Install")}
        </Button>
      </Grid>
    </Center>
  )
}

export default AppBannerTop
