import { EntityId } from "@jackfruit/common"
import { useDispatch, useSelector } from "react-redux"
import {
  pageSessionsActions,
  pageSessionsSelector,
} from "~/redux/state/pageSessions"
import { RootState } from "~/redux/store"
import { useCurrentPageId } from "./useCurrentPageId"

export const usePageSession = (id?: EntityId) => {
  const currentPageId = useCurrentPageId()
  const dispatch = useDispatch()

  const pageSession = useSelector((state: RootState) =>
    pageSessionsSelector.selectById(state, id || currentPageId)
  )!

  const pageSessionActions = {
    setStoreIds: (ids: EntityId[]) => {
      dispatch(pageSessionsActions.setStoreIds(pageSession.id, ids))
    },
    setIsReady: (isReady: boolean) => {
      dispatch(pageSessionsActions.setIsReady(pageSession.id, isReady))
    },
  }
  return { pageSession, pageSessionActions }
}
