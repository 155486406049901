import {
  BlockEntityHydrated,
  EntityId,
  FulfillmentTypes,
  PageEntity,
  PrintServiceEntity,
  PrintServiceProductEntityHydrated,
} from "@jackfruit/common"
import { SagaIterator } from "redux-saga"
import { call, select } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { printServicesSelector } from "../state/printServices"
import { RootState } from "../store"
import { getBlock } from "./blocks"
import { getCurrentCart } from "./cart"
import { getCurrentPage, getPage } from "./page"
import { getCurrentPageSession } from "./pageSession"
import { getBlockPrintServiceForFulfillment } from "./printServices"
import { getStore } from "./store"

export function* getPrintServiceProduct(
  productId: EntityId
): SagaIterator<PrintServiceProductEntityHydrated> {
  const product: PrintServiceProductEntityHydrated = yield select(state =>
    printServiceProductsSelector.selectById(state, productId)
  )

  return product
}

export function* getAllPrintServiceProducts(): SagaIterator<
  PrintServiceProductEntityHydrated[]
> {
  const products: PrintServiceProductEntityHydrated[] = yield select(
    printServiceProductsSelector.selectAll
  )

  return products
}

export function* getPrintServiceProducts(payload: {
  ids: EntityId[]
}): SagaIterator<PrintServiceProductEntityHydrated[]> {
  const { ids } = payload
  const products: PrintServiceProductEntityHydrated[] = yield select(
    (state: RootState) => printServiceProductsSelector.selectByIds(state, ids)
  )

  return products.filter(Boolean)
}

export function* getAvailableProductsForCurrentPage(): SagaIterator<
  PrintServiceProductEntityHydrated[]
> {
  const cart: CartEntity = yield call(getCurrentCart)
  const pageSession: PageSessionEntity = yield call(getCurrentPageSession)
  const { fulfillment, printServiceId } = cart
  let products: PrintServiceProductEntityHydrated[] = yield call(
    getAvailableProductsForCurrentPageAndFulfillment,
    {
      fulfillment,
      printServiceId,
    }
  )

  if (
    cart.fulfillment === "pickup" &&
    cart.storeId &&
    pageSession.hasLoadedStores
  ) {
    const storeProducts: PrintServiceProductEntityHydrated[] = yield call(
      getProductsForStore,
      {
        storeId: cart.storeId,
      }
    )

    // const pageProductIds = products.map(product => product.id)
    // const filteredProducts = storeProducts.filter(product =>
    //   pageProductIds.includes(product.id)
    // )

    const storeProductIds = storeProducts.map(product => product.id)
    const filteredProducts = products.filter(product =>
      storeProductIds.includes(product.id)
    )

    products = filteredProducts
  }

  return products
}

export function* getAvailableProductsForCurrentPageAndFulfillment({
  fulfillment,
  printServiceId,
}: {
  fulfillment: FulfillmentTypes
  printServiceId: EntityId
}): SagaIterator<PrintServiceProductEntityHydrated[]> {
  const page: PageEntity = yield call(getCurrentPage)
  const productConfigurations = page.products[fulfillment]
  const configToUse = productConfigurations[+printServiceId]

  const productIds = Object.values(configToUse)
    .sort((a, b) => b.position - a.position)
    .map(a => a.id)

  const products: PrintServiceProductEntityHydrated[] = yield call(
    getPrintServiceProducts,
    { ids: productIds }
  )
  const productsWithOverrides = products.map(product => {
    const overridedData = configToUse[Number(product.id)] ?? {}
    return {
      ...product,
      ...overridedData,
    }
  })

  return productsWithOverrides
}

export function* getPageProductsForFulfillment(payload: {
  fulfillment: FulfillmentTypes
  pageId: EntityId
}): SagaIterator<PrintServiceProductEntityHydrated[]> {
  const { pageId, fulfillment } = payload
  const page: PageEntity = yield call(getPage, { pageId })
  const printServiceIds = page.printServices[fulfillment].map(data => data.id)
  const printServices: PrintServiceEntity[] = yield select((state: RootState) =>
    printServicesSelector.selectByIds(state, printServiceIds)
  )

  const allIds = printServices.reduce<EntityId[]>((acc, printService) => {
    const productConfigurations = page.products[fulfillment][+printService.id]
    const ids = Object.keys(productConfigurations)

    return acc.concat(ids)
  }, [])

  const products: PrintServiceProductEntityHydrated[] = yield call(
    getPrintServiceProducts,
    { ids: allIds }
  )

  return products
}

export function* getBlockProductsForFulfillment(payload: {
  fulfillment: FulfillmentTypes
  blockId: EntityId
}): SagaIterator<PrintServiceProductEntityHydrated[]> {
  const { blockId, fulfillment } = payload

  const block: BlockEntityHydrated = yield call(getBlock, { blockId })
  const printService: PrintServiceProductEntityHydrated = yield call(
    getBlockPrintServiceForFulfillment,
    payload
  )

  const productConfigurations =
    block.template.products[fulfillment][printService.id]
  const ids = Object.keys(productConfigurations)

  const products: PrintServiceProductEntityHydrated[] = yield call(
    getPrintServiceProducts,
    { ids }
  )

  return products
}

export function* getProductsForStore(payload: {
  storeId: EntityId
}): SagaIterator<PrintServiceProductEntityHydrated[]> {
  const { storeId } = payload
  const store: StoreEntity = yield call(getStore, { storeId })
  const allProducts: PrintServiceProductEntityHydrated[] = yield call(
    getAllPrintServiceProducts
  )

  const storeProducts = allProducts.filter(
    product =>
      store.products.includes(product.productCode) &&
      store.printServiceId === product.printServiceId
  )

  return storeProducts
}
