import { useSelector } from "react-redux"
import { blocksSelector } from "~/redux/state/blocks"
import { EntityId } from "@jackfruit/common"
import { RootState } from "~/redux/store"

export const useBlock = (id: EntityId) => {
  const block = useSelector((state: RootState) =>
    blocksSelector.selectById(state, id)
  )

  return { block }
}
